import React, { useState, useEffect, useRef } from "react";
import Slider from "react-slick";
import { Link , useOutletContext, useParams} from 'react-router-dom'
import Head from "../component/Head"
import Card from "../component/Card"
import { RiHeartLine , RiStarSFill , RiMessage2Line , RiTruckLine  ,RiStarHalfSFill  ,RiStarSLine   } from "react-icons/ri";

import Sliders from "../component/Sliders";

const Product = () => {
    const {pid} = useParams()
    const {cartState , setCartState , cartProduct, setCartProduct} = useOutletContext();
    const [data, setData] = useState(null);
    const [nav1, setNav1] = useState(null);
    const [nav2, setNav2] = useState(null);
    let sliderRef1 = useRef(null);
    let sliderRef2 = useRef(null);
   

    const [Categories, setCategories] = useState([
        {
            images: '/product/p1.png',
            title: 'Tenniscore'
        },
        {
            images: '/product/p2.png',
            title: 'Art & Collectibles'
        },
        {
            images: '/product/p3.png',
            title: 'Bags & Purses'
        },
        {
            images: '/product/p4.png',
            title: 'Bath & Beauty'
        },
        {
            images: '/product/p5.png',
            title: 'Jewellery'
        },
        {
            images: '/product/p6.png',
            title: 'Home & Living'
        },
        {
            images: '/product/p1.png',
            title: 'Tenniscore'
        },
        {
            images: '/product/p2.png',
            title: 'Art & Collectibles'
        },
        {
            images: '/product/p3.png',
            title: 'Bags & Purses'
        },
        {
            images: '/product/p4.png',
            title: 'Bath & Beauty'
        },
        {
            images: '/product/p5.png',
            title: 'Jewellery'
        },
        {
            images: '/product/p6.png',
            title: 'Home & Living'
        }
    ]);

    
    useEffect(() => {
        setNav1(sliderRef1);
        setNav2(sliderRef2);

        const fetchProperty = async () => {
            console.log('fetching ...',`https://dummyjson.com/products?limit=4&skip=${pid-1}`);
          try {
           
                let dataJson = await fetch(`https://dummyjson.com/products?limit=4&skip=${pid-1}`).then((res)=> res.json());
                // countDiv = await data.json();
                dataJson = dataJson.products;
                // console.log('dataJson',dataJson);
                setData(dataJson);
                // let dataJson2 = await fetch('https://dummyjson.com/products?limit=12').then((res)=> res.json());
                // // countDiv = await data.json();
                // dataJson2 = dataJson2.products;
                // setCategories(dataJson2);
                
            
            } catch (error) {
                console.error('Error fetching property:', error);
          }
            };
            if(!data){

                fetchProperty();
            }

            if(data && data[0].id !== pid){
                fetchProperty();
            }
    }, [pid])
    
    console.log(data && data!==null && data[0] && data[0].images);

    const settings = {
        dots: true,
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        vertical: true,
        verticalSwiping: true,
        beforeChange: function(currentSlide, nextSlide) {
          console.log("before change", currentSlide, nextSlide);
        },
        afterChange: function(currentSlide) {
          console.log("after change", currentSlide);
        }
      };
      const inputHandler = (e) => {
        console.log(e);
    }
  return (
    <>
    <Head title={'Product'} description={"text"}/>
    <div className="container py-2 mt-3 px-md-0">
      <nav className="breadcrumb fs-8">
        <Link className="breadcrumb-item text-decoration-none text-black" to="#">Home Decor</Link>
        <Link className="breadcrumb-item text-decoration-none text-black" to="#">Art & Collectibles</Link>
        <span className="breadcrumb-item active text-theme" aria-current="page">Resin Art</span>
      </nav>
    </div>
    <div className="container">
        <div className="row row-cols-1 row-cols-lg-2 g-2 g-md-4 mb-5">
            <div className="col">
                <div className="slider-container row m-0 flex-nowrap">
                    <Slider
                        className="col-auto"
                        asNavFor={nav1}
                        ref={slider => (sliderRef2 = slider)}
                        slidesToShow={6}
                        swipeToSlide={true}
                        focusOnSelect={true}
                        vertical={true}
                        verticalSwiping={true}
                        infinite= {false}
                        style={{
                            width: '80px',
                            height: '90% !important',
                            display: 'flex',
                            alignItems: 'center'
                        }}
                    >
                    {
                    data && data[0].images.map((e,i)=> 
                                <img src={e} key={i} className="rounded-4 h-100 my-1 w-100 object-fit-cover" />
                                )
                    }
                    </Slider>
                    <Slider
                    className="col"
                    asNavFor={nav2} ref={slider => (sliderRef1 = slider)}
                    dots={false}
                    lazyLoad={true}
                    arrows={false}
                    slidesToShow={1}
                    infinite= {false}
                    style={{
                        width: '80%',
                    }}
                    >
                        {
                            data && data[0] && data[0].images.map((e,i)=> 
                                        <img src={e} key={i} className="rounded-4 h-100 w-100 object-fit-cover" />
                                        )
                        }
                    </Slider>
            
                </div>
            </div>
            <div className="row m-0">
                <Link to={`/shop/Mad Studio`} className="col-12 px-0 mb-3 mb-md-1 text-theme h-min text-decoration-none">
                {data && data[0].brand}
                </Link>
                <div className="col-12 px-0 mb-3 mb-md-1 row m-0 justify-content-between align-items-center h-min">
                    <div className="col-auto ps-0 fs-6">
                    {data && data[0].title}
                    </div>
                    <RiHeartLine className="col-auto fs-4 "/>
                </div>
                <div className="col-12 px-0 mb-3 mb-md-1">
                    <strong className="me-2 fs-6">Rs. {data && data[0].price}</strong>
                    <strike className="text-secondary me-2">Rs. {data && ((data[0].price/100)*20 + data[0].price). toFixed(2)}</strike>
                    <span className="text-theme">(20%)</span>
                </div>
                <div className="col-12 px-0 mb-3 mb-md-1 fs-9 text-secondary">
                    Inclusive Of All Taxes
                </div>
                <div className="col-12 px-0 mb-3 mb-md-1 row align-items-center">
                    <div className="ps-2 row m-0 g-1 w-auto">
                        <RiStarSFill className="mt-0 w-auto text-theme"/>
                        <RiStarSFill className="mt-0 w-auto text-theme"/>
                        <RiStarSFill className="mt-0 w-auto text-theme"/>
                        <RiStarSFill className="mt-0 w-auto text-theme"/>
                        <RiStarSFill className="mt-0 w-auto text-theme"/>
                    </div>
                    <RiMessage2Line className="w-auto pe-1"/> <span className="fs-8 w-auto px-0">97</span>
                </div>
                <div className="col-12 px-0 mb-3 mb-md-1 row m-0 align-items-center mb-3">
                    <div className="fs-4 ps-0 free-ship" ></div>
                    <span className="fs-8 w-auto px-0">Delivery by Oct 11, 2022</span>
                </div>
                <div className="col-12 px-0 mb-3 mb-md-1 mb-0">
                    Colour : Grey Marble Design 
                </div>
                <div className="col-12 px-0 mb-3 mb-md-1 row m-0 g-2 mb-3">
                    {
                    data && data[0].images.map((e,i)=> 
                        <div className="col-auto mt-0">
                        <img src={e} key={i} alt="" className={`rounded-5 ${i===0?'border border-2 border-theme':''}`} height={'40px'} width={'40px'} />
                    </div>
                                
                                )
                    }
                </div>
                <div className="col-12 px-0 mb-3 mb-md-1 row m-0">
                    <div className="mb-3 px-0">
                        <label htmlFor="" className="form-label">Diameter<sup className="text-danger">*</sup> :</label>
                        <select className="form-select focus-none" name="" id="">
                            <option selected>Select one</option>
                            <option onChange={(e)=>inputHandler(e)} value="">In</option>
                            <option onChange={(e)=>inputHandler(e)} value="">Cm</option>
                        </select>
                    </div>
                    <div className="mb-0 mb-md-3 px-0 d-flex align-items-center">
                        <label htmlFor="" className="form-label">Quantity<sup className="text-danger">*</sup> :</label>
                        <div className="input-group input-group-sm ms-2" style={{width: '80px'}}>
                            <span className="input-group-text">-</span>
                            <input type="text" className="form-control text-center"  aria-label="Amount (to the nearest rupee)" onChange={(e)=>inputHandler(e)} value="1"/>
                            <span className="input-group-text">+</span>
                        </div>
                    </div>
                    
                </div>
                <div className="col-12 px-0 row m-0 py-1 mb-3 mb-md-0">
                    <div className="accordion accordion-custom accordion-flush px-0" id="accordionFlushExample">
                    <div className="accordion-item">
                        <h2 className="accordion-header">
                        <button className="accordion-button  focus-none collapsed ps-0 py-1" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                            Add your personalisation (optional)
                        </button>
                        </h2>
                        <div id="flush-collapseOne" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                        <div className="accordion-body">Placeholder content for this accordion, which is intended to demonstrate the <code>.accordion-flush</code> class. This is the first item's accordion body.</div>
                        </div>
                    </div>
                    </div>
                </div> 
                <div className="col-12 px-0 row m-0">
                        <button type="button" className="btn btn-theme">Add to cart</button>
                </div>      
            </div>
        </div>
        <div className="col-12 mb-5">
            <ul className="nav scroll nav-underline mt-4 justify-content-md-center border-bottom">
                <li className="nav-item">
                    <a className="nav-link p-2 text-black active" id="home-tab" data-bs-toggle="tab" data-bs-target="#home-tab-pane" type="button" role="tab" aria-controls="home-tab-pane" aria-selected="true">Description</a>
                </li>
                <li className="nav-item">
                    <a className="nav-link p-2 text-black" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile-tab-pane" type="button" role="tab" aria-controls="profile-tab-pane" aria-selected="false">Rating & Review</a>
                </li>
                <li className="nav-item">
                    <a className="nav-link p-2 text-black" id="contact-tab" data-bs-toggle="tab" data-bs-target="#contact-tab-pane" type="button" role="tab" aria-controls="contact-tab-pane" aria-selected="false">Return Policy</a>
                </li>
                <li className="nav-item">
                    <a className="nav-link p-2 text-black" id="disabled-tab" data-bs-toggle="tab" data-bs-target="#disabled-tab-pane" type="button" role="tab" aria-controls="disabled-tab-pane" aria-selected="false">About Seller</a>
                </li>
            </ul>
            <div className="tab-content" id="myTabContent">
                <div className="tab-pane fade show active" id="home-tab-pane" role="tabpanel" aria-labelledby="home-tab" tabindex="0">
                    <div className="col-12 mt-5 fs-8 text-secondary">
                        <p>Lorem ipsum dolor sit amet consectetur. Semper eget iaculis adipiscing felis diam augue vivamus aliquet et. Rhoncus dolor placerat tempor vestibulum nulla. Felis et nunc potenti morbi massa tristique. Elementum non in vel et in. Etiam in morbi leo porta pellentesque ut. Aliquam purus nunc tincidunt at elementum tristique fringilla varius. Sed non leo ornare quis odio amet velit erat mattis. Porttitor tincidunt est et pretium integer sagittis. Lacus tempor nulla neque montes. Venenatis sit penatibus ipsum quis elementum consectetur. Faucibus ac in fusce ultrices purus.</p>
                        <p>Et nullam odio adipiscing nunc at. Donec et facilisis odio potenti nibh arcu amet in eros. Sem quis auctor pellentesque at in et amet nullam pellentesque. Morbi laoreet bibendum pretium in diam nibh elementum. At enim amet ut pretium volutpat. Pellentesque in vitae gravida tellus ac orci eu.
                            Lorem ipsum dolor sit amet consectetur. Semper eget iaculis adipiscing felis diam augue vivamus aliquet et. Rhoncus dolor placerat tempor vestibulum nulla. Felis et nunc potenti morbi massa tristique. Elementum non in vel et in. Etiam in morbi leo porta pellentesque ut. Aliquam purus nunc tincidunt at elementum tristique fringilla varius. Sed non leo ornare quis odio amet velit erat mattis. Porttitor tincidunt est et pretium integer sagittis. Lacus te</p>
                    </div>
                </div>
                <div className="tab-pane fade" id="profile-tab-pane" role="tabpanel" aria-labelledby="profile-tab" tabindex="0">
                    <div className="col-12 row m-0">
                        <div className="py-4 col-12 d-flex flex-nowrap m-0 gap-0">
                            <div className="d-flex flex-wrap flex-column m-0 w-auto">
                                <div className="col-auto">
                                    <span className="h1">4.8</span>
                                    <span className="text-secondary">/ 5</span>
                                </div>
                                <div className="col-auto">
                                    <div className="px-0 row m-0 g-1 w-fit">
                                        <RiStarSFill className="mt-0 w-auto text-theme"/>
                                        <RiStarSFill className="mt-0 w-auto text-theme"/>
                                        <RiStarSFill className="mt-0 w-auto text-theme"/>
                                        <RiStarSFill className="mt-0 w-auto text-theme"/>
                                        <RiStarHalfSFill   className="mt-0 w-auto text-theme"/>
                                    </div>
                                </div>
                            </div>
                            <div className="col-auto">
                                <div className="row m-0 h-100">
                                    <div className="col-12 d-flex align-items-center">
                                    Overall Rating
                                    </div>
                                    <div className="col-12 fs-8  d-flex align-items-center">
                                    3965 verified rating
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr className="bg-secondary my-3" />
                        <div className="col-12 fs-6 fw-medium">
                        Photos from reviews
                        </div>
                        <div className="py-2">
                        <Sliders data={Categories && Categories.map((e,i) => {
                                return <Link to="/category" key={i} className="col col-md-4 col-lg-1 p-2 text-decoration-none text-black">
                                        <img src={e.images} alt="" height="auto" className="mb-3 border-0 rounded-3 w-100 object-fit-cover" />
                                        <p className="d-flex mb-0 align-items-center">&nbsp;</p>
                                    </Link>
                            })} 
                            settings={{
                                dots: false,
                                infinite: false,
                                speed: 500,
                                slidesToShow: 8,
                                slidesToScroll: 8,
                                initialSlide: 0,
                                responsive: [
                                {
                                    breakpoint: 1024,
                                    settings: {
                                    slidesToShow: 5,
                                    slidesToScroll: 5,
                                    infinite: true,
                                    dots: true
                                    }
                                },
                                {
                                    breakpoint: 600,
                                    settings: {
                                    slidesToShow: 4,
                                    slidesToScroll: 4,
                                    initialSlide: 4
                                    }
                                },
                                {
                                    breakpoint: 480,
                                    settings: {
                                    slidesToShow: 3,
                                    slidesToScroll: 3
                                    }
                                }
                                ]
                            }} 
                            
                            id="SlickSlide1"
                            />
                        </div>
                        <hr className="bg-secondary my-3" />
                        <div className="col-12 fs-6 fw-medium mb-3">
                        Customer Reviews (14)
                        </div>
                        <div className="col-12">
                            <div className="col-12">
                                <div className="col scroll d-flex align-items-center p-3 rounded-2 bg-custom-light mb-3">
                                    <span>Filter By: </span>
                                        <select className="form-select focus-none form-select-sm w-auto ms-2" name="" id="" >
                                            <option selected>Rating</option>
                                            <option onChange={(e)=>inputHandler(e)} value="">New Delhi</option>
                                            <option onChange={(e)=>inputHandler(e)} value="">Istanbul</option>
                                            <option onChange={(e)=>inputHandler(e)} value="">Jakarta</option>
                                        </select>
                                        <select className="form-select  focus-none form-select-sm w-auto ms-2" name="" id="" >
                                            <option selected>Review with image</option>
                                            <option onChange={(e)=>inputHandler(e)} value="">New Delhi</option>
                                            <option onChange={(e)=>inputHandler(e)} value="">Istanbul</option>
                                            <option onChange={(e)=>inputHandler(e)} value="">Jakarta</option>
                                        </select>
                                    
                                </div>
                                <div className="col-12 row row-cols-1 row-cols-md-2 g-2 m-0 align-items-center px-0 p-1">
                                    {
                                        Array.from({length: 3}).map((e,i)=><div key={i} className="col">
                                        <div className="col-12 p-3 border border-1 rounded-2">
                                            <div className="col-12 mb-2 px-0">
                                                <div className="px-0 row m-0 g-1 w-fit">
                                                    <RiStarSFill className="mt-0 w-auto text-theme"/>
                                                    <RiStarSFill className="mt-0 w-auto text-theme"/>
                                                    <RiStarSFill className="mt-0 w-auto text-theme"/>
                                                    <RiStarSFill className="mt-0 w-auto text-theme"/>
                                                    <RiStarHalfSFill   className="mt-0 w-auto text-theme"/>
                                                </div>
                                            </div>
                                            <p className="mb-2 fw-light text-black">Absolutely beautiful- my husband loved it (for our 5th anniversary = wood) and we even got our date engraved on the back! And it came so well packaged! Looked exactly as</p>
                                            <div className="col-12 d-flex m-0 flex-column">
                                                <img src="/product/p2.png" alt="" className="mb-2" width={40} height={40} />
                                                <div className="col-12 d-flex m-0 justify-content-between">
                                                    <div className="col-auto fs-8 w-auto">Simran Saini</div>
                                                    <div className="col-auto fs-8 text-secondary w-auto">28 Feb, 2024</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>)
                                    }
                                </div>
                                <div className="col"></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="tab-pane fade" id="contact-tab-pane" role="tabpanel" aria-labelledby="contact-tab" tabindex="0">
                    <div className="col-12 mt-5 fs-8 text-secondary">
                        <p>Lorem ipsum dolor sit amet consectetur. Semper eget iaculis adipiscing felis diam augue vivamus aliquet et. Rhoncus dolor placerat tempor vestibulum nulla. Felis et nunc potenti morbi massa tristique. Elementum non in vel et in. Etiam in morbi leo porta pellentesque ut. Aliquam purus nunc tincidunt at elementum tristique fringilla varius. Sed non leo ornare quis odio amet velit erat mattis. Porttitor tincidunt est et pretium integer sagittis. Lacus tempor nulla neque montes. Venenatis sit penatibus ipsum quis elementum consectetur. Faucibus ac in fusce ultrices purus.</p>
                        <p>Et nullam odio adipiscing nunc at. Donec et facilisis odio potenti nibh arcu amet in eros. Sem quis auctor pellentesque at in et amet nullam pellentesque. Morbi laoreet bibendum pretium in diam nibh elementum. At enim amet ut pretium volutpat. Pellentesque in vitae gravida tellus ac orci eu.
                            Lorem ipsum dolor sit amet consectetur. Semper eget iaculis adipiscing felis diam augue vivamus aliquet et. Rhoncus dolor placerat tempor vestibulum nulla. Felis et nunc potenti morbi massa tristique. Elementum non in vel et in. Etiam in morbi leo porta pellentesque ut. Aliquam purus nunc tincidunt at elementum tristique fringilla varius. Sed non leo ornare quis odio amet velit erat mattis. Porttitor tincidunt est et pretium integer sagittis. Lacus te</p>
                    </div>
                </div>
                <div className="tab-pane fade" id="disabled-tab-pane" role="tabpanel" aria-labelledby="disabled-tab" tabindex="0">
                    <div className="col-12 mt-5 fs-8 text-secondary">
                        <p>Lorem ipsum dolor sit amet consectetur. Semper eget iaculis adipiscing felis diam augue vivamus aliquet et. Rhoncus dolor placerat tempor vestibulum nulla. Felis et nunc potenti morbi massa tristique. Elementum non in vel et in. Etiam in morbi leo porta pellentesque ut. Aliquam purus nunc tincidunt at elementum tristique fringilla varius. Sed non leo ornare quis odio amet velit erat mattis. Porttitor tincidunt est et pretium integer sagittis. Lacus tempor nulla neque montes. Venenatis sit penatibus ipsum quis elementum consectetur. Faucibus ac in fusce ultrices purus.</p>
                        <p>Et nullam odio adipiscing nunc at. Donec et facilisis odio potenti nibh arcu amet in eros. Sem quis auctor pellentesque at in et amet nullam pellentesque. Morbi laoreet bibendum pretium in diam nibh elementum. At enim amet ut pretium volutpat. Pellentesque in vitae gravida tellus ac orci eu.
                            Lorem ipsum dolor sit amet consectetur. Semper eget iaculis adipiscing felis diam augue vivamus aliquet et. Rhoncus dolor placerat tempor vestibulum nulla. Felis et nunc potenti morbi massa tristique. Elementum non in vel et in. Etiam in morbi leo porta pellentesque ut. Aliquam purus nunc tincidunt at elementum tristique fringilla varius. Sed non leo ornare quis odio amet velit erat mattis. Porttitor tincidunt est et pretium integer sagittis. Lacus te</p>
                    </div>
                </div>
            </div>
           
        </div>
        <div className="container py-5 px-0 px-md" >
            <div className="text-center mb-3 mb-md-5">
                <div className="fs-2 heading">More from this shop</div>
                <div className="text-center subheading mb-4">Lorem ipsum dolor, sit amet consectetur adipisicing elit. Incidunt sapiente expe</div>
                <div className="row gap-2 justify-content-center">
                    <button type="button" name="" id="" className="btn w-auto btn-outline-secondary rounded-5">
                        <RiHeartLine className="me-2"/>Follow
                    </button>
                    <button type="button" name="" id="" className="btn w-auto btn-outline-secondary rounded-5">
                        See more
                    </button>
                </div>
            </div>
            <div className="position-relative h-100">
                <div className="d-flex flex-md-wrap flex-nowrap w-100 overflow-x-auto h-100">
                    {data && data.map((e) => {
                        return <Card cartState={cartState} setCartState={setCartState} cartProduct={cartProduct} setCartProduct={setCartProduct} data={e}  classN={'col-8 col-md-3 p-2'}  />
                    })}
                </div>
            </div>
        </div>
        <div className="container py-5 px-0 px-md" >
            <div className="text-center mb-3 mb-md-5">
                <div className="fs-2 heading">You may also like</div>
                <div className="text-center subheading mb-4">Lorem ipsum dolor, sit amet consectetur adipisicing elit. Incidunt sapiente expe</div>
                <div className="row gap-2 justify-content-center">
                    <button type="button" name="" id="" className="btn w-auto btn-outline-secondary rounded-5">
                        See more
                    </button>
                </div>
            </div>
            <div className="position-relative h-100">
            <div className="d-flex flex-md-wrap flex-nowrap w-100 overflow-x-auto h-100">
                    {data && data.map((e) => {
                        return <Card cartState={cartState} setCartState={setCartState} cartProduct={cartProduct} setCartProduct={setCartProduct} data={e}  classN={'col-8 col-md-3 p-2'}  />
                    })}
                </div>
            </div>
        </div>
        <div className="container py-5 px-0 px-md" >
            <div className="text-center mb-3 mb-md-5">
                <div className="fs-2 heading">Recently Viewed</div>
                <div className="text-center subheading ">Lorem ipsum dolor, sit amet consectetur adipisicing elit. Incidunt sapiente expe</div>
            </div>
            <div className="position-relative h-100">
                <div className="d-flex flex-md-wrap flex-nowrap w-100 overflow-x-auto h-100">
                    {data && data.map((e) => {
                        return <Card cartState={cartState} setCartState={setCartState} cartProduct={cartProduct} setCartProduct={setCartProduct} data={e}  classN={'col-8 col-md-3 p-2'}  />
                    })}
                </div>
            </div>
        </div>
    </div>
    </>
  )
}

export default Product
