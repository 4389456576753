import { useEffect, useState } from "react";
import Card from "../component/Card";
import Head from "../component/Head"
import { Link ,useOutletContext} from 'react-router-dom'
import { IoIosArrowDroprightCircle } from "react-icons/io";
import Sliders from "../component/Sliders";
function Home() {
    const {cartState , setCartState , cartProduct, setCartProduct} = useOutletContext();

    const [data, setData] = useState(null);
    const [Categories, setCategories] = useState([
        {
            images: '/product/p1.png',
            title: 'Tenniscore'
        },
        {
            images: '/product/p2.png',
            title: 'Art & Collectibles'
        },
        {
            images: '/product/p3.png',
            title: 'Bags & Purses'
        },
        {
            images: '/product/p4.png',
            title: 'Bath & Beauty'
        },
        {
            images: '/product/p5.png',
            title: 'Jewellery'
        },
        {
            images: '/product/p6.png',
            title: 'Home & Living'
        },
        {
            images: '/product/p1.png',
            title: 'Tenniscore'
        },
        {
            images: '/product/p2.png',
            title: 'Art & Collectibles'
        },
        {
            images: '/product/p3.png',
            title: 'Bags & Purses'
        },
        {
            images: '/product/p4.png',
            title: 'Bath & Beauty'
        },
        {
            images: '/product/p5.png',
            title: 'Jewellery'
        },
        {
            images: '/product/p6.png',
            title: 'Home & Living'
        }
    ]);
    
    useEffect(() => {
        const fetchProperty = async () => {
            console.log('fetching ...',`https://dummyjson.com/products?limit=4`);
          try {
           
                let dataJson = await fetch('https://dummyjson.com/products?limit=4').then((res)=> res.json());
                // countDiv = await data.json();
                dataJson = dataJson.products;
                // console.log('dataJson',dataJson);
                setData(dataJson);
                // let dataJson2 = await fetch('https://dummyjson.com/products?limit=12').then((res)=> res.json());
                // // countDiv = await data.json();
                // dataJson2 = dataJson2.products;
                // setCategories(dataJson2);
         
            } catch (error) {
                console.error('Error fetching property:', error);
          }
            };
    if(!data){
        fetchProperty();
    }
    }, [data])
    
  return (<>
    <Head title={'Home'} description={"text"}/>
    <div className="sliders mb-4 d-none d-md-block">
        <div className="d-flex justify-content-center align-items-center ">
            <div className="p-3 p-md-4">Home Decor</div>
            <div className="p-3 p-md-4">Metrial</div>
            <div className="p-3 p-md-4">Well Art</div>
            <div className="p-3 p-md-4">Furniture</div>
            <div className="p-3 p-md-4">Jewellery</div>
            <div className="p-3 p-md-4">Other</div>
        </div>
    </div>
    <div className="container pb-4 pb-md-0" >
        <div className="row m-0 h-100 w-100">
            <div className="col-6 col-md-4 p-0">
                <div className="d-flex h-25 w-100">
                    <div className="w-25 h-100 p-1 p-md-2">
                        <img src="/product/p1.png" alt="" className="object-fit-cover rounded-2 h-100 w-100" />
                    </div>
                    <div className="w-75 h-100 p-1 p-md-2">
                        <img src="/product/p2.png" alt="" className="object-fit-cover rounded-2 h-100 w-100" />
                    </div>
                </div>
                <div className="d-flex h-75 w-100">
                    <div className="w-50 h-100 p-1 p-md-2">
                        <img src="/product/p3.png" alt="" className="object-fit-cover rounded-2 h-100 w-100" />
                    </div>
                    <div className="w-50 h-100 p-1 p-md-2">
                        <img src="/product/p4.png" alt="" className="object-fit-cover rounded-2 h-100 w-100" />
                    </div>
                </div>
            </div>
            <div className="col-6 col-md-4 p-0">
                <div className="d-flex h-75 w-100">
                    <div className="w-50 h-100 p-1 p-md-2">
                        <img src="/product/p5.png" alt="" className="object-fit-cover rounded-2 h-100 w-100" />
                    </div>
                    <div className="w-50 h-100 p-1 p-md-2">
                        <img src="/product/p6.png" alt="" className="object-fit-cover rounded-2 h-100 w-100" />
                    </div>
                </div>
                <div className="d-flex h-25 w-100">
                    <div className="w-75 h-100 p-1 p-md-2">
                        <img src="/product/p4.png" alt="" className="object-fit-cover rounded-2 h-100 w-100" />
                    </div>
                    <div className="w-25 h-100 p-1 p-md-2">
                        <img src="/product/p5.png" alt="" className="object-fit-cover rounded-2 h-100 w-100" />
                    </div>
                </div>
            </div>
            <div className="d-none d-md-block col-md-4 p-0">
                <div className="d-flex h-25 w-100">
                    <div className="w-25 h-100 p-1 p-md-2">
                        <img src="/product/p2.png" alt="" className="object-fit-cover rounded-2 h-100 w-100" />
                    </div>
                    <div className="w-75 h-100 p-1 p-md-2">
                        <img src="/product/p6.png" alt="" className="object-fit-cover rounded-2 h-100 w-100" />
                    </div>
                </div>
                <div className="d-flex h-75 w-100">
                    <div className="w-50 h-100 p-1 p-md-2">
                        <img src="/product/p3.png" alt="" className="object-fit-cover rounded-2 h-100 w-100" />
                    </div>
                    <div className="w-50 h-100 p-1 p-md-2">
                        <img src="/product/p5.png" alt="" className="object-fit-cover rounded-2 h-100 w-100" />
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div className="container py-4 py-md-5 mt-0 mt-md-5" >
        <div id="carouselExampleSlidesOnly" className="carousel slide p-0 h-100" data-bs-ride="carousel">
            <div className="carousel-inner h-100">
                <div className="carousel-item overflow-hidden border-0 rounded-3 h-100 active">
                <img src="/banner-mobile.png" className="d-block d-md-none w-100 " alt="..." />
                <img src="/banner.png" className="d-none d-md-block w-100 " alt="..." />
                </div>
                <div className="carousel-item overflow-hidden border-0 rounded-3 h-100">
                <img src="/banner-mobile.png" className="d-block d-md-none w-100 " alt="..." />
                <img src="/banner.png" className="d-none d-md-block w-100 " alt="..." />
                </div>
                <div className="carousel-item overflow-hidden border-0 rounded-3 h-100">
                <img src="/banner-mobile.png" className="d-block d-md-none w-100 " alt="..." />
                <img src="/banner.png" className="d-none d-md-block w-100 " alt="..." />
                </div>
            </div>
        </div>
    </div>
    <div className="container py-4 py-md-5">
        <div className="text-center mb-4">
            <div className="fs-2 heading">Top Categories</div>
            <div className="text-center subheading">Lorem ipsum dolor, sit amet consectetur adipisicing elit. Incidunt sapiente expe</div>
        </div>
            <Sliders data={Categories && Categories.map((e,i) => {
                return <Link to="/category" key={i} className="col col-md-3 col-lg-2 p-2 text-decoration-none text-black">
                        <img src={e.images} alt="" height="auto" className="mb-3 border-0 rounded-3 w-100 object-fit-cover" />
                        <p className="d-flex mb-0 align-items-center"> <span className="text-truncate">{e.title}</span> <i className="d-flex fs-6 justify-content-center align-items-center ms-1 bi bi-arrow-right-short"></i></p>
                    </Link>
            })} 
            settings={{
                dots: false,
                infinite: false,
                speed: 500,
                slidesToShow: 6,
                slidesToScroll: 6,
                initialSlide: 0,
                responsive: [
                  {
                    breakpoint: 1024,
                    settings: {
                      slidesToShow: 4,
                      slidesToScroll: 4,
                      infinite: true,
                      dots: true
                    }
                  },
                  {
                    breakpoint: 600,
                    settings: {
                      slidesToShow: 3,
                      slidesToScroll: 3,
                      initialSlide: 3
                    }
                  },
                  {
                    breakpoint: 480,
                    settings: {
                      slidesToShow: 3,
                      slidesToScroll: 3
                    }
                  }
                ]
              }} 
              
              id="SlickSlide1"
              />
    </div>
    <div className="container py-4 py-md-5" >
        <div className="text-center mb-4">
            <div className="fs-2 heading">New Arrivals</div>
            <div className="text-center subheading">Lorem ipsum dolor, sit amet consectetur adipisicing elit. Incidunt sapiente expe</div>
        </div>
        <div className="position-relative h-100">
            <div className="d-flex flex-md-wrap flex-nowrap w-100 overflow-x-auto h-100">
                {data && data.map((e,i) => {
                    return <Card  key={i} classN={'col-8 col-md-3 p-2'} cartState={cartState} setCartState={setCartState} cartProduct={cartProduct} setCartProduct={setCartProduct} data={e} />
                })}
            </div>
        </div>
    </div>
    <div className="container py-4 py-md-5" >
        <div id="BannerSlider" className="carousel slide h-100 p-0 " data-bs-ride="carousel">
            <div className="carousel-inner h-100">
                <div className="carousel-item overflow-hidden border-0 rounded-3 h-100 active">
                    <img src="/Rectangle 42006.png" className="d-block w-100 object-fit-cover" alt="..." style={{height:'40vh'}} />
                    <div className="carousel-caption carousel-caption-align d-none d-md-block">
                        <h1 className="text-black">BECOME A SELLER</h1>
                        <p className="text-dark">Move Your Business Local to Globle With Us</p>
                        <button className="btn btn-theme">Get Started</button>
                    </div>
                </div>  
            </div>
        </div>
    </div>
    <div className="container py-4 py-md-5 my-5 bg-light-theme rounded-2">
        <div className="row px-0 px-md-5 m-0">
            <div className="col-6 col-md-3 p-3 text-center">
                <img src="/Vector.png" alt="" className="mb-3" height={65} />
                <p className="mb-2 fw-medium">Easy Returns</p>
                <p className="fs-9">All our products are directly sourced from brands</p>
            </div>
            <div className="col-6 col-md-3 p-3 text-center">
                <img src="/Group.png" alt="" className="mb-3" height={65} />
                <p className="mb-2 fw-medium">24 Hours Customer care </p>
                <p className="fs-9">All our products are directly sourced from brands</p>
            </div>
            <div className="col-6 col-md-3 p-3 text-center">
                <img src="/Group (1).png" alt="" className="mb-3" height={65} />
                <p className="mb-2 fw-medium">Pay on Delivery</p>
                <p className="fs-9">All our products are directly sourced from brands</p>
            </div>
            <div className="col-6 col-md-3 p-3 text-center">
                <img src="/free-shipping 1.png" alt="" className="mb-3" height={65} />
                <p className="mb-2 fw-medium">Free Shipping</p>
                <p className="fs-9">All our products are directly sourced from brands</p>
            </div>
        </div>
    </div>
  </>
  );
}

export default Home;
