import React , { useEffect, useState } from "react";
import { Link, useParams } from 'react-router-dom'
import Head from "../component/Head"
import { FaRegDotCircle , FaRegEdit } from "react-icons/fa";
import { FaRegCircleCheck } from "react-icons/fa6";
import { MdKeyboardArrowDown } from "react-icons/md";
import { VscClose } from "react-icons/vsc";
import { CiHeart } from "react-icons/ci";



const Order = () => {

    let {id} = useParams()
    
    const [order, setOrder] = useState([{
        id: 'VIPS800113047',
        title : 'Custom OCEAN ECHOES Ground Resin Lamp',
        image: "/product/p4.png",
        price : 2552.00,
        discount: 35,
        qty: 1,
        color: 'White',
        Size: 30,
        delivery: '31/08/2024',
        status: 'Delivered',
        adcharge: 50
    },
    {
        id: 'VIPS800113048',
        title : 'Custom OCEAN ECHOES Ground Resin Lamp',
        image: "/product/p5.png",
        price : 1245.00,
        discount: 10,
        qty: 1,
        color: 'Gold',
        Size: 7,
        delivery: '31/08/2024',
        status: 'Cancelled',
        adcharge: 23
    },
    {
        id: 'VIPS800113049',
        title : 'Custom OCEAN ECHOES Ground Resin Lamp',
        image: "/product/p6.png",
        price : 3252.00,
        discount: 25,
        qty: 1,
        color: 'Blue',
        Size: 103,
        delivery: '31/08/2024',
        status: 'Ongoing',
        adcharge: 30
    }])
    
let data = order.filter((e)=>e.id === id)[0];
  return (
    <>
    <Head title={'Order'} description={"text"}/>
    <div className="container">
        <div className="col-12">
            <h4 className="text-center my-4">Order Details</h4>
        </div>
    </div>
    <hr />
    <div className="col-12 row m-0">
            <div className="col-md-8 col-12">
                <div action="" method="post" className={`p-4 col-12  d-block`}>
                    <div className="row m-0">
                    {order && order.filter((e)=>e.id === id).map((e,i)=><div key={i} className="col-12 mb-3 border border-1 border-dark-subtle rounded-3 p-3 position-relative">
                                        <div className="row m-0">
                                            <div className="col-12 px-0 pb-2 d-flex m-0 justify-content-between">
                                                <span className="fs-7">Order No #{e.id}</span>
                                                <Link to={`/profile/order/${e.id}`} className="fs-8 text-black">View Details</Link>
                                            </div>
                                            <hr />
                                            <div className="ps-0 col-auto">
                                                <img src={e.image} alt="" className="w-auto rounded-3" height={'100px'} />
                                            </div>
                                            <div className="pe-0 col">
                                                <div className="col-12 mb-2 fs-7">
                                                    <div className="d-flex justify-content-between w-100">
                                                        <span>{e.title}</span>
                                                        <span className={`text-${e.status === 'Cancelled' ? 'danger' : e.status === 'Ongoing' ? 'warning' : 'theme' } fs-8`}>{e.status}</span>
                                                    </div>
                                                </div>
                                                <div className="col-12 mb-2 fs-7">
                                                    <div className="d-flex justify-content-between w-100">
                                                        <div>
                                                            <span className="text-secondary">Total :</span> <strong className="text-black">Rs.{e.price - ((e.discount/100)*parseFloat(e.price)).toFixed(2)}</strong> <strike className="text-secondary">Rs. {e.price}</strike> <span className="text-theme">({e.discount}%)</span>
                                                        </div>
                                                        <span className="text-black fs-8">On {e.delivery}</span>
                                                    </div>
                                                </div>
                                                <div className="col-12 mb-2 px-0 fs-8 d-flex align-items-center">
                                                    <div className="d-flex">
                                                        <span className="text-secondary">Qty :</span>
                                                        <span className="text-black">{e.qty}</span>
                                                    </div>
                                                    <div className="d-flex ms-3">
                                                        <span className="text-secondary">Size :</span>
                                                        <span className="text-black">{e.Size}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>)}
                        <div className="col-12 mb-3 border border-1 border-dark-subtle rounded-3 p-3 position-relative">
                            <div className="row m-0">
                                <div className="col-12 px-0 pb-2 d-flex m-0 justify-content-between">
                                    <span className="fs-7">Address Details</span>
                                    <Link className="fs-8 text-black"></Link>
                                </div>
                                <hr />
                                <div className="pe-0 col">
                                    <div className="col-12 mb-2 fs-7">
                                        <div className="d-flex justify-content-between w-100">
                                            <span>Supriya Morade</span>
                                        </div>
                                    </div>
                                    <div className="col-12 mb-2 fs-7">
                                        <div className="d-flex justify-content-between w-100">
                                           <p className="text-secondary mb-0 fs-7">Supriya kirana store, near vittal mandir, moradewadi, Manchar, Ambegoan,
                                           Maharashtra - 410503</p>
                                        </div>
                                    </div>
                                    <div className="col-12 mb-2 px-0 fs-8 d-flex align-items-center">
                                        <div className="d-flex">
                                            <span className="text-secondary">Mobile :</span>
                                            <span className="text-black">7057523522</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
            <div className="col-md-4 col-12">
                <div className="p-4 col-12 mb-4 border border-1 border-dark-subtle rounded-3 ">
                    <div className="col-12 h4">
                    Order Summary
                    </div>
                    <hr />
                    <div className="col-12">
                        <div className="d-flex fs-7 mb-2 m-0 justify-content-between">
                            <span>Price :</span>
                            <span className="text-black fw-bold">₹ 2,525.00</span>
                        </div>
                        <div className="d-flex fs-7 mb-2 m-0 justify-content-between">
                            <span>Shop Discount :</span>
                            <span className="text-black fw-bold">-₹ {((data.discount/100)*parseFloat(data.price)).toFixed(2)}</span>
                        </div>
                        <div className="d-flex fs-7 mb-2 m-0 justify-content-between">
                            <span>Shipping Charges :</span>
                            <span className="text-black fw-bold">+₹ {data.discount}</span>
                        </div>
                    </div>
                    <hr />
                    <div className="col-12">
                        <div className="d-flex fs-7 mb-2 m-0 justify-content-between">
                            <span>Total Amount :</span>
                            <span className="text-black fw-bold">₹ {(data.price - ((data.discount/100)*parseFloat(data.price)).toFixed(2)) + data.adcharge}</span>
                        </div>
                    </div>
                    <div className="d-grid gap-2 mt-4">
                        <button type="button" name="" id="" className="btn btn-info">
                        Place Order
                        </button>
                    </div>
                    
                </div>
                <div className="p-4 col-12 border border-1 border-dark-subtle rounded-3 ">
                    <div className="col-12 h4">
                    Return / Refund Policy
                    </div>
                    <hr />
                    <div className="col-12 fs-8">
                      <p className="mb-1">In case of return, we ensure quick refunds. Full amount will be refunded excluding 
                        Convenience Fee</p>
                        <span className="text-theme ">Read Policy</span>
                    </div>
                    
                    
                </div>
            </div>
        </div>
    </>
  )
}

export default Order
