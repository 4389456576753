import React, { useState } from 'react'
import { Outlet } from 'react-router-dom'
import Header from './component/Header'
import Footer from './component/Footer'
import Cart from './component/Cart'
import Login from './component/Login'
function Layout() {
  const [cartState, setCartState] = useState(false)
  const [cartProduct, setCartProduct] = useState([])
  const [loginState, setLoginState] = useState(false)
  
  const [navCustomHandler, setNavCustomHandler] = useState('My Account')
  return (
    <>
     <Header loginState={loginState}  setLoginState={setLoginState} navCustomHandler={navCustomHandler}  setNavCustomHandler={setNavCustomHandler} />
     <Outlet context={{ cartState: cartState , setCartState: setCartState , cartProduct:cartProduct, setCartProduct:setCartProduct , navCustomHandler:navCustomHandler, setNavCustomHandler:setNavCustomHandler }} />
     <Footer />
     <Cart cartState={cartState} setCartState={setCartState} cartProduct={cartProduct} setCartProduct={setCartProduct} />
     <Login loginState={loginState}  setLoginState={setLoginState} />
    </>
  )
}

export default Layout
