import React from "react";
import Head from '../../component/Head'

const Overview = () => {

    let menu = document.getElementById('overview');
  if (menu) {
    menu.classList.add('active');
  }
  return (<>
    <Head title={'Dashboard | Overview'} description={"text"}/>
    <div className="col p-3">
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb fs-7">
            <li className="breadcrumb-item"><a href="#">Dashboard</a></li>
            <li className="breadcrumb-item active" aria-current="page">Overview</li>
        </ol>
      </nav>
      <div className="row row-cols-2 row-cols-lg-4 g-2 g-lg-3 my-3">
        <div className="col">
          <div className="bg-white shadow rounded-2 p-3">
            OverView
          </div>
        </div>
        <div className="col">
          <div className="bg-white shadow rounded-2 p-3">
            OverView
          </div>
        </div>
        <div className="col">
          <div className="bg-white shadow rounded-2 p-3">
            OverView
          </div>
        </div>
        <div className="col">
          <div className="bg-white shadow rounded-2 p-3">
            OverView
          </div>
        </div>
      </div>
      <div className="col-12 bg-white shadow rounded-2 p-3" style={{height: '50vh'}}>
        <div className="col-12 row justify-content-between">
          <div className="col d-flex align-items-center fw-semibold">
              <span>
              Overview
              </span>
              <span className="material-icons text-theme fs-8 ms-2">
              radio_button_unchecked
              </span>
          </div>
          <div className="col"></div>
        </div>
      </div>
    </div>
    </>
  )
}

export default Overview
