import React from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { MdLocationPin } from "react-icons/md";
import { RiShoppingCartLine , RiUserLine , RiHeartLine , RiMenuLine  } from "react-icons/ri";
import { isMobile , isTablet } from "react-device-detect";

const Header = ({loginState , setLoginState , navCustomHandler, setNavCustomHandler}) => {
    const {pathname} = useLocation()
    const navigate = useNavigate()
    const redirect = (direct)=>{
        navigate(direct)
    }

  return (
   <>
    <div className="sticky-top bg-white">
        <div className="navbar container gap-3 p-4 py-3 navbar-expand-lg bg-white navbar-light px-3">
            <div className="navbar-brand py-0">
                <Link to="/">
                    <img loading="eager" src="/tulo.png" alt="logo" className="" height={28} />
                </Link>
            </div>
            <button type="button" className="navbar-toggler d-none d-md-block bg-white" data-bs-toggle="collapse" aria-label="nav-btns"
                data-bs-target="#">
                <span className="navbar-toggler-icon fs-6"></span>
            </button>
            <div className="d-none d-md-flex">
                <div className="position-relative border border-1 overflow-hidden rounded-5" style={{width: '20vw', borderColor: '#f0f0f0 !important'}}>
                    <input type="text" name="search" id="search" className="fs-7 border-0 px-3 py-2 h-100 w-100" placeholder="Search..." />
                    <span className="icons icon-magnifier position-absolute end-0 top-50 me-3" onClick={()=>redirect('/search')} style={{transform: "translate(0px, -50%)"}}></span>
                </div>
            </div>
            <div className='d-flex'>
            {pathname === '/profile' && isMobile && !isTablet ? <div className="navbar-nav d-flex flex-nowrap flex-row gap-3 w-auto">
                        <li className="nav-item dropdown">
                            <Link className="nav-link dropdown-toggle drop-after-none" to="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                <RiUserLine className='fs-5'  />
                            </Link>
                            <ul className="dropdown-menu position-fixed top-0 end-0" style={{marginTop:'75px'}}>
                                <li><div onClick={()=>setNavCustomHandler('My Account')} className="dropdown-item" >My Account</div></li>
                                <li><div onClick={()=>setNavCustomHandler('My Orders')} className="dropdown-item" >My Orders</div></li>
                                <li><div onClick={()=>setNavCustomHandler('My Wishlist')} className="dropdown-item" >My Wishlist</div></li>
                                <li><div onClick={()=>setNavCustomHandler('Recently Searched')} className="dropdown-item" >Recently Searched</div></li>
                                <li><div onClick={()=>setNavCustomHandler('Addresses')}  className="dropdown-item" >Address</div></li>
                                <li><div onClick={()=>setNavCustomHandler('Sign out')} className="dropdown-item" >Signout</div></li>
                            </ul>
                        </li>
                        <Link className="nav-link d-flex align-items-center align-middle" to="/cart" ><RiShoppingCartLine className='fs-5'/></Link>
                        </div>
                    : isMobile && !isTablet ? <Link className="nav-link  d-flex align-items-center align-middle" onClick={()=>setLoginState(true)}><RiUserLine className='fs-5'  /></Link> : ''
                    }
                     <button type="button" className="navbar-toggler bg-white" data-bs-toggle="collapse" aria-label="nav-btns"
                data-bs-target="#navbarCollapse">
                <span className="navbar-toggler-icon fs-6"></span>
            </button>
            </div>
            <div className="collapse navbar-collapse border-0 z-3 py-3 py-md-0 py-lg-0" id="navbarCollapse">
                <div className="navbar-nav ms-auto gap-2">
                    <div className="nav-link d-flex align-items-center align-middle">
                        <Link href="#banner" className="d-flex align-items-center align-middle btn btn-theme  rounded-5 fs-8 px-3">Sell to tulo</Link>
                    </div>
                    <a href="#hero-sec" className="nav-link d-flex align-items-center align-middle fs-8">Offers</a>
                    <div className="nav-link d-flex align-items-center align-middle">
                        <button type="button" className='btn btn-outline-secondary rounded-5 fs-8 d-flex justify-content-center items'>
                                <MdLocationPin className='text-theme fs-5 me-2'/>Mumbai &nbsp;
                        </button>
                    </div>
                    <Link className="nav-link  d-flex align-items-center align-middle"><RiHeartLine className='fs-5' /></Link>
                    {pathname === '/profile' && isMobile && !isTablet ? 
                       ''
                    : <Link className="nav-link  d-flex align-items-center align-middle" onClick={()=>setLoginState(true)}><RiUserLine className='fs-5'  /></Link>
                    }
                    <Link className="nav-link  d-flex align-items-center align-middle" to="/cart" ><RiShoppingCartLine className='fs-5'/></Link>
                    
                </div>
            </div>
            <div className="d-flex d-md-none col-12 justify-content-center py-2 bg-white">
                <div className="position-relative border border-1 overflow-hidden rounded-5" style={{width: '100vw', borderColor: '#f0f0f0 !important'}}>
                    <input type="text" name="search" id="search" className="fs-7 border-0 px-3 py-2 h-100 w-100" placeholder="Search..." />
                    <span className="icons icon-magnifier position-absolute end-0 top-50 me-3" onClick={()=>redirect('/search')} style={{transform: "translate(0px, -50%)"}}></span>
                </div>
            </div>
        </div>
       
    </div>
   </>
  )
}

export default Header;
