import React , { useEffect, useState } from "react";
import { Link } from 'react-router-dom'
import Head from "../component/Head"

const Category = () => {
    
    const [Categories, setCategories] = useState([
        {
            images: '/product/p1.png',
            title: 'Tenniscore'
        },
        {
            images: '/product/p2.png',
            title: 'Art & Collectibles'
        },
        {
            images: '/product/p3.png',
            title: 'Bags & Purses'
        },
        {
            images: '/product/p4.png',
            title: 'Bath & Beauty'
        },
        {
            images: '/product/p5.png',
            title: 'Jewellery'
        },
        {
            images: '/product/p6.png',
            title: 'Home & Living'
        },
        {
            images: '/product/p1.png',
            title: 'Tenniscore'
        },
        {
            images: '/product/p2.png',
            title: 'Art & Collectibles'
        },
        {
            images: '/product/p3.png',
            title: 'Bags & Purses'
        },
        {
            images: '/product/p4.png',
            title: 'Bath & Beauty'
        },
        {
            images: '/product/p5.png',
            title: 'Jewellery'
        },
        {
            images: '/product/p6.png',
            title: 'Home & Living'
        }
    ]);

  return (
    <>
    <Head title={'Category'} description={"text"}/>
    <div className="container py-2 mt-3 px-3 px-md-0">
      <nav className="breadcrumb fs-8">
        <Link className="breadcrumb-item text-decoration-none text-black" to="#">Home</Link>
        <span className="breadcrumb-item active text-theme" aria-current="page">Category</span>
      </nav>
    </div>
    <div className="container ">
        <div className="d-flex justify-content-center align-items-center p-5 rounded-3 bg-category" style={{backgroundImage:`url('/category banner.png')`}}>
            <div className="h3">
                Category
            </div>
        </div>
    </div>
    <div className="container mb-3 mb-md-5">
        <div className="row m-0 pt-5">
        {Categories && Categories.map((e,i) => {
                return <Link to={`/search/${e.title}`} key={i} className="col-6 col-md-3 col-lg-2 p-2 text-decoration-none text-black mb-4">
                        <img src={e.images} alt="" style={{height: '20vh'}} className="mb-3 border-0 rounded-3 w-100 object-fit-cover" />
                        <p className="d-flex mb-0 align-items-center">{e.title} <i className="d-flex fs-6 justify-content-center align-items-center ms-1 bi bi-arrow-right-short"></i></p>
                    </Link>
            })}
        </div>
    </div>
    </>
  )
}

export default Category
