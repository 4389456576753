import React from 'react'
import { Link } from 'react-router-dom'

const Menu = () => {

  return (
    <div className="row m-0 py-3 p-0 col-2 shadow" style={{height: `calc(100vh - 60px)`}}>
      <div className=" overflow-y-auto px-0">
        <div className=' list-unstyled'>
          <Link to="/dashboard" id="overview" className={'d-flex align-items-center py-2 ps-3 menu-list '}>
            <span className="mf material-symbols-outlined fs-5 me-2">
            donut_large
            </span>
            <span>Overview</span>
          </Link>
          <Link to="/dashboard/analytics" id="analytics" className={'d-flex align-items-center py-2 ps-3 menu-list '}>
            <span className="mf material-symbols-outlined fs-5 me-2">
            bar_chart_4_bars
            </span>
            <span>Analytics</span>
          </Link>
          <Link to="/dashboard/product" id="product" className={'d-flex align-items-center py-2 ps-3 menu-list '}>
            <span className="mf material-symbols-outlined fs-5 me-2">
            package_2
            </span>
            <span>Product</span>
          </Link>
          <Link to="/dashboard/users" id="users" className={'d-flex align-items-center py-2 ps-3 menu-list '}>
            <span className="mf material-symbols-outlined fs-5 me-2">
            group
            </span>
            <span>Users</span>
          </Link>
        </div>
      </div>
    </div>
  )
}

export default Menu
