import React from 'react'

const Analytics = () => {
    let menu = document.getElementById('analytics');
    if (menu) {
      menu.classList.add('active');
    }
  return (
    <div className="col p-3">
      Analytics
    </div>
  )
}

export default Analytics
