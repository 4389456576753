import React , { useEffect, useState } from "react";
import { Link } from 'react-router-dom'
import Head from "../component/Head"
import { FaRegDotCircle } from "react-icons/fa";
import { FaRegCircleCheck } from "react-icons/fa6";
import { MdKeyboardArrowDown } from "react-icons/md";
import { VscClose } from "react-icons/vsc";
import { CiHeart } from "react-icons/ci";
import { RxPencil2 } from "react-icons/rx";


const ShoppingCart = () => {
    const [active, setActive] = useState({'cart': true,'adddress':false,'payment':false})
    const [currectPage, setCurrectPage] = useState('cart')
    const [state, setState] = useState(true)
    const [edit, setEdit] = useState(true)
    const [order, setOrder] = useState([{
        id: 'VIPS800113047',
        title : 'Custom OCEAN ECHOES Ground Resin Lamp',
        image: "/product/p4.png",
        price : 2552.00,
        discount: 35,
        qty: 1,
        color: 'White',
        Size: 30,
        delivery: 'Deliver in 2 Days'
    },
    {
        id: 'VIPS800113048',
        title : 'Custom OCEAN ECHOES Ground Resin Lamp',
        image: "/product/p5.png",
        price : 1245.00,
        discount: 10,
        qty: 1,
        color: 'Gold',
        Size: 7,
        delivery: 'Deliver in 2 Days'
    },
    {
        id: 'VIPS800113049',
        title : 'Custom OCEAN ECHOES Ground Resin Lamp',
        image: "/product/p6.png",
        price : 3252.00,
        discount: 25,
        qty: 1,
        color: 'Blue',
        Size: 103,
        delivery: 'Deliver in 2 Days'
    }])

    let keys = Object.keys(active);

    // Find the index of the current key
    let currentIndex = keys.indexOf(currectPage);

    // Find the next key (loop back to the first if it's the last key)
    let nextPage = keys[(currentIndex + 1) % keys.length];

    const handleSteps = (index) => {
        setCurrectPage(index)
        setActive({...active,
            [index]: !active[index]
        })
        console.log(active);
         currentIndex = keys.indexOf(currectPage);
        // Find the next key (loop back to the first if it's the last key)
         nextPage = keys[(currentIndex + 1) % keys.length];
        
    }
    const inputHandler = (e) => {
        console.log(e);
        
    }
  return (
    <>
    <Head title={'Category'} description={"text"}/>
    <div className="container mb-5">
        <div className="col-12">
            <div className="d-flex m-0 align-items-center gap-2 justify-content-center py-3 mb-3 mb-md-4">
                <div onClick={()=>handleSteps('cart')} className={`staps d-flex align-items-center gap-2 ${active['cart'] ? 'active' : ''}`}>{!active['cart'] ? <FaRegDotCircle/> : <FaRegCircleCheck className="text-theme"/>} Shopping Cart </div>
                <div onClick={()=>handleSteps('adddress')} className={`staps d-flex align-items-center gap-2 ${active['adddress'] ? 'active' : ''}`}><hr style={{width: '8vw'}} className={`border border-1 ${active['adddress'] ? 'border-dark' : 'border-secondary'}`}/>{!active['adddress'] ? <FaRegDotCircle/> : <FaRegCircleCheck className="text-theme"/>} Address </div>
                <div onClick={()=>handleSteps('payment')} className={`staps d-flex align-items-center gap-2 ${active['payment'] ? 'active' : ''}`}><hr style={{width: '8vw'}} className={`border border-1 ${active['payment'] ? 'border-dark' : 'border-secondary'}`}/>{!active['payment'] ? <FaRegDotCircle/> : <FaRegCircleCheck className="text-theme"/>} Payment </div>
            </div>
        </div>
        <div className="col-12 row m-0">
            <div className="col-md-8 col-12">
                <div action="" method="post" className={`px-0 px-md-4 col-12  ${'cart' === currectPage ? 'd-block' : 'd-none'}`}>
                    <div className="row m-0">
                        {order && order.map((e,i)=><div key={i} className="col-12 mb-0 border-bottom border-1 rounded-0 p-3 py-3 position-relative" style={{backgroundColor: '#f8f8f8 !important'}}>
                            <div className="grid-card m-0">
                                <div className="ps-md-0 px-0 col-auto">
                                    <img src={e.image} alt="" className="w-auto rounded-3 card-image" />
                                </div>
                                <div className="ps-2 w-100">
                                    <div className="col-12 mb-2 w-100 text-truncate">
                                        {e.title}
                                    </div>
                                    <div className="col-12 mb-2 fs-7">
                                    <strong className="text-black">Rs.{e.price - ((e.discount/100)*parseFloat(e.price)).toFixed(2)}</strong> <strike className="text-secondary fw-light">Rs. {e.price}</strike> <span className="text-theme  fw-light">({e.discount}%)</span>
                                    </div>
                                    <div className="col-12 mb-2 px-0 fs-8 d-flex flex-wrap align-items-center">
                                        <div className="d-flex me-2 mb-2 mb-md-0 align-items-center w-sm-100">
                                            <span htmlFor="" className="d-flex mb-0">Qty<sup className="text-danger">*</sup> :</span>
                                            <div className="input-group input-group-sm ms-2" style={{width: '80px'}}>
                                                <span className="input-group-text">-</span>
                                                <input type="text" className="form-control text-center fs-9"  aria-label="Amount (to the nearest rupee)" onChange={(e)=>inputHandler(e)} value={e.qty}/>
                                                <span className="input-group-text">+</span>
                                            </div>
                                        </div>
                                        <div className="d-flex me-2 mb-0">
                                            <span className="text-secondary">Color :</span>
                                            <span className="text-black">{e.color}</span>
                                        </div>
                                        <div className="d-flex me-2 mb-0">
                                            <span className="text-secondary">Size :</span>
                                            <span className="text-black">{e.Size} <MdKeyboardArrowDown/></span>
                                        </div>
                                    </div>
                                    <div className="col-12 fs-9 text-secondary">
                                    {e.delivery}
                                    </div>
                                </div>
                            </div>
                            <VscClose className="position-absolute top-0 end-0 z-1 mt-3 me-3 fs-5" />
                            <CiHeart className="position-absolute bottom-0 end-0 z-1 mb-3 me-3 fs-5" />
                        </div>)
                        }
                    </div>
                </div>
                <div action="" method="post" className={`px-0 px-md-4 col-12  ${'adddress' === currectPage ? 'd-block' : 'd-none'}`}>
                {state ? <div className="col-12"><div className="h4 mb-3">Select Delivery Address</div>
                    <div className="h6 mb-0">Default Address</div>
                    <div className="mb-3 p-0">
                    {edit ? <div className="form-check d-flex align-items-start border-bottom border-1  rounded-0 p-3 position-relative" style={{backgroundColor: '#f8f8f8 !important'}}>
                             <input className="form-radio-input my-1" type="radio" onChange={(e)=>inputHandler(e)} value="" id="" checked/>
                            <div className="col px-2">
                                <label className="form-check-label mb-2" htmlFor="">Robert Fox</label>
                                <p className="fs-8 mb-0">6391 Elgin St. Celina, Delaware 10299</p>
                                <p className="fs-8 mb-0">Mobile : <strong>7057523522</strong></p>
                            </div>
                            <RxPencil2 onClick={()=>setEdit(false)} className="fs-5 text-edit"/>
                        </div>
                        :
                        <div className="form-check row m-0 border-bottom border-1  rounded-0 p-3 position-relative" style={{backgroundColor: '#f8f8f8 !important'}}>
                            <div className="col-12 d-flex align-items-center gap-2 mb-4">
                                <input type="radio" name="edit" id="edit" onClick={(e)=>inputHandler(e)} checked /> 
                                <span>Edit Address</span>
                            </div>
                            <form action="" method="post" className="col-12 ">
                                <div className="mb-3">
                                    <input type="text" className="form-control" name="" id="" aria-describedby="helpId" placeholder="Name*"/>
                                </div>
                                <div className="mb-3">
                                    <input type="text" className="form-control" name="" id="" aria-describedby="helpId" placeholder="Moblie No*"/>
                                </div>
                                <div className="mb-3">
                                    <input type="text" className="form-control" name="" id="" aria-describedby="helpId" placeholder="Pin code*"/>
                                </div>
                                <div className="mb-3">
                                    <input type="text" className="form-control" name="" id="" aria-describedby="helpId" placeholder="Address (House No,Building, Street, Area)*"/>
                                </div>
                                <div className="mb-3">
                                    <input type="text" className="form-control" name="" id="" aria-describedby="helpId" placeholder="Locality / Town*"/>
                                </div>
                                <div className="mb-3">
                                    <input type="text" className="form-control" name="" id="" aria-describedby="helpId" placeholder="City*"/>
                                </div>
                                <div className="mb-3">
                                    <input type="text" className="form-control" name="" id="" aria-describedby="helpId" placeholder="State*"/>
                                </div>
                                <div className="mb-4">
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" onChange={(e)=>inputHandler(e)} value="" id="" />
                                        <label className="form-check-label" htmlFor="">Make this my default address</label>
                                    </div>
                                </div>
                                <div className="row m-0 px-0">
                                    <div className="col-12 col-md-8 ps ps-md-0 mb-3 mb-md-0">
                                        <button type="button" name="" id="" className="col-12 btn btn-theme" onClick={()=>setEdit(true)}>
                                            Save and Deliver Here
                                        </button>
                                    </div>
                                    <div className="col-12 col-md-4 pe pe-md-0">
                                        <button type="button" name="" id="" className="col-12 btn btn-outline-dark" onClick={()=>setEdit(true)}>
                                            Cancel
                                        </button>
                                    </div>
                                </div>
                                
                            </form>
                        </div>
                        }
                    </div>
                    <div className="h6 mb-0">Other Address</div>
                    <div className="mb-3 p-0">
                        <div className="form-check d-flex align-items-start border-bottom border-1 rounded-0 p-3 position-relative" style={{backgroundColor: '#f8f8f8 !important'}}>
                            <input className="form-radio-input my-1" type="radio" onChange={(e)=>inputHandler(e)} value="" id="" />
                            <div className="col px-2">
                                <label className="form-check-label mb-2" htmlFor="">Floyd Miles</label>
                                <p className="fs-8 mb-0">4140 Parker Rd. Allentown, New Mexico 31134</p>
                                <p className="fs-8 mb-0">Mobile : <strong>8525463542</strong></p>
                            </div>
                            <RxPencil2 className="fs-5 text-edit" />
                        </div>
                    </div> </div> : ''}
                    <form action="" className={`col-12 pb-3 border-bottom border-1 rounded-0 mb-3 h6`} style={{backgroundColor: '#f8f8f8 !important'}}>
                    {state ? <span onClick={()=>setState(false)}>+ &nbsp; Add New Address</span> :
                    <div className="">
                        <div className="h5 mb-3">Contact Details</div>
                        <div className="mb-3">
                            <input type="text" className="form-control" name="" id="" aria-describedby="helpId" placeholder="Name*"/>
                        </div>
                        <div className="mb-3">
                            <input type="text" className="form-control" name="" id="" aria-describedby="helpId" placeholder="Mobile No*"/>
                        </div>
                        <div className="h5 mb-3">Address</div>
                        <div className="mb-3">
                            <input type="text" className="form-control" name="" id="" aria-describedby="helpId" placeholder="Pin code*"/>
                        </div>
                        <div className="mb-3">
                            <input type="text" className="form-control" name="" id="" aria-describedby="helpId" placeholder="Address (House No,Building, Street, Area)*"/>
                        </div>
                        <div className="mb-3">
                            <input type="text" className="form-control" name="" id="" aria-describedby="helpId" placeholder="Locality / Town*"/>
                        </div>
                        <div className="mb-3 row">
                            <div className="col">
                                <input type="text" className="form-control" name="" id="" aria-describedby="helpId" placeholder="City*"/>
                            </div>
                            <div className="col">
                                <input type="text" className="form-control" name="" id="" aria-describedby="helpId" placeholder="State*"/>
                            </div>
                        </div>
                        <div className="mb-4">
                            <div className="form-check">
                                <input className="form-check-input" type="checkbox"  onChange={(e)=>inputHandler(e)} value="" id="" />
                                <label className="form-check-label" htmlFor="">Make this my default address</label>
                            </div>
                        </div>
                        <div className="d-grid gap-2">
                            <button type="button" name="" onClick={()=>setState(true)} id="" className="btn btn-info">
                            Add to Address
                            </button>
                        </div>
                    </div>
                    }
                        
                    </form>
                </div>
                <form action="" method="post" className={`px-0 px-md-4 col-12 col-md-11 col-lg-10 border border-1 border-dark-subtle rounded-3 ${'payment' === currectPage ? 'd-block' : 'd-none'}`}>
                    <div className="h4 mb-3">Payment</div>
                </form>
            </div>
            <div className="col-md-4 col-12 mt-4 mt-md-0">
                <div className="p-4 col-12 mb-4 border border-1  rounded-3 " style={{backgroundColor: '#f8f8f8 !important'}}>
                    <div className="col-12 h4">
                    Order Summary
                    </div>
                    <hr />
                    <div className="col-12">
                        <div className="d-flex fs-7 mb-2 m-0 justify-content-between">
                            <span>Price :</span>
                            <span className="text-black fw-bold">₹ 2,525.00</span>
                        </div>
                        <div className="d-flex fs-7 mb-2 m-0 justify-content-between">
                            <span>Shop Discount :</span>
                            <span className="text-black fw-bold">-₹ 0.00</span>
                        </div>
                        <div className="d-flex fs-7 mb-2 m-0 justify-content-between">
                            <span>Shipping Charges :</span>
                            <span className="text-black fw-bold">+₹ 50.00</span>
                        </div>
                    </div>
                    <hr />
                    <div className="col-12">
                        <div className="d-flex fs-7 mb-2 m-0 justify-content-between">
                            <span>Total Amount :</span>
                            <span className="text-black fw-bold">₹ 1,959.00</span>
                        </div>
                    </div>
                    <div className="d-grid gap-2 mt-4">
                        <button type="button" name="" id="" className={`btn btn-theme`}  onClick={()=>handleSteps(nextPage)}>
                        Place Order
                        </button>
                    </div>
                    
                </div>
                <div className="p-4 col-12 border border-1 rounded-3 " style={{backgroundColor: '#f8f8f8 !important'}}>
                    <div className="col-12 h4">
                    Return / Refund Policy
                    </div>
                    <hr />
                    <div className="col-12 fs-8">
                      <p className="mb-1">In case of return, we ensure quick refunds. Full amount will be refunded excluding 
                        Convenience Fee</p>
                        <span className="text-theme ">Read Policy</span>
                    </div>
                    
                    
                </div>
            </div>
        </div>
    </div>
    </>
  )
}

export default ShoppingCart
