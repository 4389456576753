import React , { useEffect, useState } from "react";
import { Link ,useOutletContext } from 'react-router-dom'
import Head from "../component/Head"
import Card from "../component/Card"
import { FaRegDotCircle } from "react-icons/fa"
import { MdKeyboardArrowDown } from "react-icons/md";
import { VscClose } from "react-icons/vsc";
import { CiHeart } from "react-icons/ci";
import { VscSettings } from "react-icons/vsc";
import { CgSearch } from "react-icons/cg";
import { RxPencil2 } from "react-icons/rx";

const Profile = () => {

    const {cartState , setCartState , cartProduct, setCartProduct, navCustomHandler, setNavCustomHandler} = useOutletContext();
    const [state, setState] = useState(true)
    const [edit, setEdit] = useState(true)
     
    const [data, setData] = useState(null);

    const inputHandler = (e) => {
        console.log(e);
        
    }
    useEffect(() => {
        const fetchData = async () => {
          try {
            const response = await fetch('https://dummyjson.com/products?limit=9');
            const dataJson = await response.json();
            setData(dataJson.products);
          } catch (error) {
            console.error("Failed to fetch products", error);
          }
        };
    
        // Fetch data only if data is null
        if (data === null) {
          fetchData();
        }
      }, [data]);
      
      const [order, setOrder] = useState([{
        id: 'VIPS800113047',
        title : 'Custom OCEAN ECHOES Ground Resin Lamp',
        image: "/product/p4.png",
        price : 2552.00,
        discount: 35,
        qty: 1,
        color: 'White',
        Size: 30,
        delivery: '31/08/2024',
        status: 'Delivered'
    },
    {
        id: 'VIPS800113048',
        title : 'Custom OCEAN ECHOES Ground Resin Lamp',
        image: "/product/p5.png",
        price : 1245.00,
        discount: 10,
        qty: 1,
        color: 'Gold',
        Size: 7,
        delivery: '31/08/2024',
        status: 'Cancelled'
    },
    {
        id: 'VIPS800113049',
        title : 'Custom OCEAN ECHOES Ground Resin Lamp',
        image: "/product/p6.png",
        price : 3252.00,
        discount: 25,
        qty: 1,
        color: 'Blue',
        Size: 103,
        delivery: '31/08/2024',
        status: 'Ongoing'
    }])
    return (
        <>
        <Head title={'Category'} description={"text"}/>
        <div className="container">
            <div className="col-12">
                <h4 className="text-center my-4">{navCustomHandler}</h4>
            </div>
        </div>
        <hr />
        <div className="container my-5 px-0 px-md">
            <div className="row m-0 col-12">
                <div className="d-flex align-items-start gap-3">
                    <div className="flex-column col-3 me-3 filter" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                        <div onClick={()=>setNavCustomHandler('My Account')} className={`p-3 nav-custom ${navCustomHandler === 'My Account' ? 'active' : ''}`} id="v-pills-home-tab" data-bs-toggle="pill" data-bs-target="#v-pills-home" role="tab" aria-controls="v-pills-home" aria-selected="true">My Account</div>
                        <div onClick={()=>setNavCustomHandler('My Orders')} className={`p-3 nav-custom ${navCustomHandler === 'My Orders' ? 'active' : ''}`} id="v-pills-profile-tab" data-bs-toggle="pill" data-bs-target="#v-pills-profile" role="tab" aria-controls="v-pills-profile" aria-selected="false">My Orders</div>
                        <div onClick={()=>setNavCustomHandler('My Wishlist')} className={`p-3 nav-custom ${navCustomHandler === 'My Wishlist' ? 'active' : ''}`} id="v-pills-disabled-tab" data-bs-toggle="pill" data-bs-target="#v-pills-disabled" role="tab" aria-controls="v-pills-disabled" aria-selected="false">My Wishlist</div>
                        <div onClick={()=>setNavCustomHandler('Recently Searched')} className={`p-3 nav-custom ${navCustomHandler === 'Recently Searched' ? 'active' : ''}`} id="v-pills-messages-tab" data-bs-toggle="pill" data-bs-target="#v-pills-messages" role="tab" aria-controls="v-pills-messages" aria-selected="false">Recently Searched</div>
                        <div onClick={()=>setNavCustomHandler('Addresses')} className={`p-3 nav-custom ${navCustomHandler === 'Addresses' ? 'active' : ''}`} id="v-pills-settings-tab" data-bs-toggle="pill" data-bs-target="#v-pills-settings" role="tab" aria-controls="v-pills-settings" aria-selected="false">Addresses</div>
                        <div onClick={()=>setNavCustomHandler('Sign out')} className={`p-3 nav-custom ${navCustomHandler === 'Sign out' ? 'active' : ''}`} id="v-pills-settings-tab" data-bs-toggle="pill" data-bs-target="#v-pills-signout" role="tab" aria-controls="v-pills-settings" aria-selected="false">Sign out</div>
                    </div>
                    <div className="tab-content col-12 col-md-9 " id="v-pills-tabContent">
                        <div className={`tab-pane fade ${navCustomHandler === 'My Account'  ? 'show active' : ''} `} id="v-pills-home" role="tabpanel" aria-labelledby="v-pills-home-tab" tabIndex="0">
                            <div className="col-12 p-0 rounded-3">
                                <form action="" method="post" className="col-12 border border-1 p-4 rounded-3" style={{backgroundColor: '#f8f8f8'}}>
                                    <div className="col-12 mb-4 h6">
                                    Personal Details
                                    </div>
                                    <div className="mb-3">
                                        <input type="text" className="form-control py-2" name="" id="" aria-describedby="helpId" placeholder="Name*"/>
                                    </div>
                                    <div className="mb-3">
                                        <input type="text" className="form-control py-2" name="" id="" aria-describedby="helpId" placeholder="Moblie No*"/>
                                    </div>
                                    <div className="mb-3">
                                        <input type="date" className="form-control py-2" name="" id="" aria-describedby="helpId" placeholder="Date of birth"/>
                                    </div>
                                    
                                    <div className="row m-0 px-0">
                                        <div className="col-12 col-md-8 ps ps-md-0">
                                            <button type="button" name="" id="" className="col-12 btn btn-theme mb-3 mb-md-0" >
                                                Save and Deliver Here
                                            </button>
                                        </div>
                                        <div className="col-12 col-md-4 pe pe-md-0">
                                            <button type="button" name="" id="" className="col-12 btn btn-outline-dark" >
                                                Cancel
                                            </button>
                                        </div>
                                    </div>
                                    
                                </form>
                            </div>
                        </div>
                        <div className={`tab-pane fade ${navCustomHandler === 'My Orders'  ? 'show active' : ''}`} id="v-pills-profile" role="tabpanel" aria-labelledby="v-pills-profile-tab" tabIndex="1">
                            <div className="col-12 col-md-11 col-lg-10 ">
                                <div className="col-12 mb-4 d-flex align-items-center justify-content-between">
                                    <div className="d-flex position-relative" >
                                        <input type="search" className="border-0 ps-5 pe-3 py-2 rounded-2" name="" id="" placeholder="Search by product name and order number" style={{backgroundColor: '#f0f0f0'}}/>
                                        <CgSearch className=" position-absolute top-50 start-0 ms-3 fs-5" style={{transform:'translate(0,-50%)'}}/>
                                    </div>
                                    <div className="d-flex align-items-center">
                                    <VscSettings />
                                    <span className="fs-8">Filter</span>
                                    </div>
                                </div>
                                <div className="row m-0">
                                    {order && order.map((e,i)=><div key={i} className="col-12 mb-3 border border-1 rounded-3 p-3 position-relative" style={{backgroundColor: '#f8f8f8 !important'}}>
                                        <div className="row m-0">
                                            <div className="col-12 px-0 pb-2 d-flex m-0 justify-content-between">
                                                <span className="fs-7">Order No #{e.id}</span>
                                                <Link to={`/profile/order/${e.id}`} className="fs-8 text-black">View Details</Link>
                                            </div>
                                            <hr className="border-1"  style={{backgroundColor: '#f8f8f8'}}/>
                                            <div className="ps-0 col-auto">
                                                <img src={e.image} alt="" className="w-auto rounded-3" height={'100px'} />
                                            </div>
                                            <div className="pe-0 col">
                                                <div className="col-12 mb-2 fs-7">
                                                    <div className="d-flex justify-content-between w-100">
                                                        <span>{e.title}</span>
                                                        <span className={`text-${e.status === 'Cancelled' ? 'danger' : e.status === 'Ongoing' ? 'warning' : 'theme' } fs-8`}>{e.status}</span>
                                                    </div>
                                                </div>
                                                <div className="col-12 mb-2 fs-7">
                                                    <div className="d-flex justify-content-between w-100">
                                                        <div>
                                                            <span className="text-secondary">Total :</span> <strong className="text-black">Rs.{e.price - ((e.discount/100)*parseFloat(e.price)).toFixed(2)}</strong> <strike className="text-secondary">Rs. {e.price}</strike> <span className="text-theme">({e.discount}%)</span>
                                                        </div>
                                                        <span className="text-black fs-8">On {e.delivery}</span>
                                                    </div>
                                                </div>
                                                <div className="col-12 mb-2 px-0 fs-8 d-flex align-items-center">
                                                    <div className="d-flex">
                                                        <span className="text-secondary">Qty :</span>
                                                        <span className="text-black">{e.qty}</span>
                                                    </div>
                                                    <div className="d-flex ms-3">
                                                        <span className="text-secondary">Size :</span>
                                                        <span className="text-black">{e.Size}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>)}

                                </div>
                            </div>
                        </div>
                        <div className={`tab-pane fade ${navCustomHandler === 'My Wishlist'  ? 'show active' : ''}`} id="v-pills-disabled" role="tabpanel" aria-labelledby="v-pills-disabled-tab" tabIndex="2">
                            <div className="col-12 ">
                                <div className="d-flex flex-wrap h-100">
                                    {data && data.map((e) => {
                                        return <Card cartState={cartState} setCartState={setCartState} cartProduct={cartProduct} setCartProduct={setCartProduct} data={e} classN={'col-6 col-md-4 p-2'} icon="fill" />
                                    })}
                                </div>
                            </div>
                        </div>
                        <div className={`tab-pane fade ${navCustomHandler === 'Recently Searched'  ? 'show active' : ''}`} id="v-pills-messages" role="tabpanel" aria-labelledby="v-pills-messages-tab" tabIndex="3">
                            <div className="col-12 col-md-11 col-lg-10">
                                <div className="accordion accordion-profile accordion-flush" id="accordionFlushExample">
                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                                        Lorem ipsum dolor sit amet consectetur.
                                        </button>
                                        </h2>
                                        <div id="flush-collapseOne" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                                        <div className="accordion-body">Placeholder content for this accordion, which is intended to demonstrate the <code>.accordion-flush</code> class. This is the first item's accordion body.</div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                                        Lorem ipsum dolor sit amet consectetur.Lorem ipsum dol
                                        </button>
                                        </h2>
                                        <div id="flush-collapseTwo" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                                        <div className="accordion-body">Placeholder content for this accordion, which is intended to demonstrate the <code>.accordion-flush</code> class. This is the second item's accordion body. Let's imagine this being filled with some actual content.</div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                                        Lorem ipsum dolor sit amet consectetur.
                                        </button>
                                        </h2>
                                        <div id="flush-collapseThree" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                                        <div className="accordion-body">Placeholder content for this accordion, which is intended to demonstrate the <code>.accordion-flush</code> class. This is the third item's accordion body. Nothing more exciting happening here in terms of content, but just filling up the space to make it look, at least at first glance, a bit more representative of how this would look in a real-world application.</div>
                                        </div>
                                    </div>
                                </div>      
                            </div>
                        </div>
                        <div className={`tab-pane fade ${navCustomHandler === 'Addresses' ? 'show active' : ''} `} id="v-pills-settings" role="tabpanel" aria-labelledby="v-pills-settings-tab" tabIndex="4">
                            <div className="col-12 col-md-11 col-lg-10 border rounded-2 p-3" style={{backgroundColor: '#f8f8f8 !important'}}>
                                <div className="row m-0">
                                    <div className="col-12 mb-0 p-0">
                                    {edit ? <div className="form-check d-flex align-items-start border-bottom border-1 rounded-0 p-3 position-relative" style={{backgroundColor: '#f8f8f8 !important'}}>
                                            <div className="col px-2">
                                                <label className="form-check-label mb-2 h6" htmlFor="">Robert Fox</label>
                                                <p className="fs-8 mb-0">6391 Elgin St. Celina, Delaware 10299</p>
                                                <p className="fs-8 mb-0">Mobile : <strong>7057523522</strong></p>
                                            </div>
                                            <RxPencil2 onClick={()=>setEdit(false)} className="fs-5 text-edit"/>
                                        </div>
                                        :
                                        <div className="form-check row m-0 border-bottom border-1 rounded-0 p-3 position-relative" style={{backgroundColor: '#f8f8f8 !important'}}>
                                            <div className="col-12 d-flex align-items-center gap-2 mb-4">
                                                <input type="radio" name="edit" id="edit" onClick={(e)=>inputHandler(e)} checked /> 
                                                <span>Edit Address</span>
                                            </div>
                                            <form action="" method="post" className="col-12 ">
                                                <div className="mb-3">
                                                    <input type="text" className="form-control" name="" id="" aria-describedby="helpId" placeholder="Name*"/>
                                                </div>
                                                <div className="mb-3">
                                                    <input type="text" className="form-control" name="" id="" aria-describedby="helpId" placeholder="Moblie No*"/>
                                                </div>
                                                <div className="mb-3">
                                                    <input type="text" className="form-control" name="" id="" aria-describedby="helpId" placeholder="Pin code*"/>
                                                </div>
                                                <div className="mb-3">
                                                    <input type="text" className="form-control" name="" id="" aria-describedby="helpId" placeholder="Address (House No,Building, Street, Area)*"/>
                                                </div>
                                                <div className="mb-3">
                                                    <input type="text" className="form-control" name="" id="" aria-describedby="helpId" placeholder="Locality / Town*"/>
                                                </div>
                                                <div className="mb-3">
                                                    <input type="text" className="form-control" name="" id="" aria-describedby="helpId" placeholder="City*"/>
                                                </div>
                                                <div className="mb-3">
                                                    <input type="text" className="form-control" name="" id="" aria-describedby="helpId" placeholder="State*"/>
                                                </div>
                                                <div className="mb-4">
                                                    <div className="form-check">
                                                        <input className="form-check-input" type="checkbox" onChange={(e)=>inputHandler(e)} value="" id="" />
                                                        <label className="form-check-label" htmlFor="">Make this my default address</label>
                                                    </div>
                                                </div>
                                                <div className="row m-0 px-0">
                                                    <div className="col-12 col-md-8 ps ps-md-0">
                                                        <button type="button" name="" id="" className="col-12 btn btn-theme" onClick={()=>setEdit(true)}>
                                                            Save and Deliver Here
                                                        </button>
                                                    </div>
                                                    <div className="col-12 col-md-4 pe pe-md-0">
                                                        <button type="button" name="" id="" className="col-12 btn btn-outline-theme" onClick={()=>setEdit(true)}>
                                                            Cancel
                                                        </button>
                                                    </div>
                                                </div>
                                                
                                            </form>
                                        </div>
                                        }
                                    </div>
                                    <div className="col-12 mb-0 p-0">
                                        <div className="form-check d-flex align-items-start border-bottom border-1 rounded-0 p-3 position-relative" style={{backgroundColor: '#f8f8f8 !important'}}>
                                            <div className="col px-2">
                                                <label className="form-check-label mb-2 h6" htmlFor="">Floyd Miles</label>
                                                <p className="fs-8 mb-0">4140 Parker Rd. Allentown, New Mexico 31134</p>
                                                <p className="fs-8 mb-0">Mobile : <strong>8525463542</strong></p>
                                            </div>
                                            <RxPencil2 onClick={()=>setEdit(false)} className="fs-5 text-edit"/>
                                        </div>
                                    </div>
                                    <form action="" className={`col-12 pt-3 h6`} style={{backgroundColor: '#f8f8f8 !important'}}>
                                        {state ? <span onClick={()=>setState(false)}>+ &nbsp; Add New Address</span> :
                                        <div className="">
                                            <div className="h5 mb-3">Contact Details</div>
                                            <div className="mb-3">
                                                <input type="text" className="form-control" name="" id="" aria-describedby="helpId" placeholder="Name*"/>
                                            </div>
                                            <div className="mb-3">
                                                <input type="text" className="form-control" name="" id="" aria-describedby="helpId" placeholder="Mobile No*"/>
                                            </div>
                                            <div className="h5 mb-3">Address</div>
                                            <div className="mb-3">
                                                <input type="text" className="form-control" name="" id="" aria-describedby="helpId" placeholder="Pin code*"/>
                                            </div>
                                            <div className="mb-3">
                                                <input type="text" className="form-control" name="" id="" aria-describedby="helpId" placeholder="Address (House No,Building, Street, Area)*"/>
                                            </div>
                                            <div className="mb-3">
                                                <input type="text" className="form-control" name="" id="" aria-describedby="helpId" placeholder="Locality / Town*"/>
                                            </div>
                                            <div className="mb-3 row">
                                                <div className="col">
                                                    <input type="text" className="form-control" name="" id="" aria-describedby="helpId" placeholder="City*"/>
                                                </div>
                                                <div className="col">
                                                    <input type="text" className="form-control" name="" id="" aria-describedby="helpId" placeholder="State*"/>
                                                </div>
                                            </div>
                                            <div className="mb-4">
                                                <div className="form-check">
                                                    <input className="form-check-input" type="checkbox" onChange={(e)=>inputHandler(e)} value="" id="" />
                                                    <label className="form-check-label" htmlFor="">Make this my default address</label>
                                                </div>
                                            </div>
                                            <div className="d-grid gap-2">
                                                <button type="button" name="" onClick={()=>setState(true)} id="" className="btn btn-theme">
                                                Add to Address
                                                </button>
                                            </div>
                                        </div>
                                        }
                                            
                                        </form>
                                </div>
                            </div>
                        </div>
                        <div className={`tab-pane fade ${navCustomHandler === 'Sign out'  ? 'show active' : ''}`} id="v-pills-settings" role="tabpanel" aria-labelledby="v-pills-settings-tab" tabIndex="5">...</div>
                    </div>
                </div>
            </div>
        </div>
        </>
  )
}

export default Profile
