import React from 'react';
import ReactDOM from 'react-dom/client';
import './theme.css';
import reportWebVitals from './reportWebVitals';
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import Layout from './Layout';
import Home from './pages/Home';
import Demo from './pages/Demo';
import Login from './component/Login';
import Dashboard from './dashboard/Layout'
import Overview from './dashboard/pages/Overview'
import { GoogleOAuthProvider } from '@react-oauth/google';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import Analytics from './dashboard/pages/Analytics';
import Product from './pages/Product';
import Category from './pages/Category';
import Search from './pages/Search';
import ShoppingCart from './pages/ShoppingCart';
import Profile from './pages/Profile';
import Order from './pages/Order';
import Shop from './pages/Shop';
import AboutUs from './pages/AboutUs';

// import Navigation from './Navigation';

const root = ReactDOM.createRoot(document.getElementById('root'));
const router = createBrowserRouter([
  {
    element: <Layout />,
    path: "/",
    children: [
      {
        path: "/",
        element: <Home />,
      },
      {
        element: <Category />,
        path: "/category"
      },
      {
        element: <Product />,
        path: "/product/:pid"
      },
      {
        element: <Search />,
        path: "/search"
      },
      {
        element: <ShoppingCart />,
        path: "/cart"
      },
      {
        element: <Profile />,
        path: "/profile"
      },
      {
        element: <AboutUs />,
        path: "/aboutus"
      },
      {
        element: <Shop />,
        path: "/shop/:shopid"
      },
      {
        path: "/profile/order/:id",
        element: <Order />,
      },
      {
        element: <Search />,
        path: "/search/:category"
      },
    ],
  },
  {
    element: <Dashboard />,
    path: "/dashboard",
    children: [
      {
        path: "/dashboard",
        element: <Overview />,
      },
      {
        path: "/dashboard/analytics",
        element: <Analytics />,
      },
      {
        path: "/dashboard/product",
        element: <Product />,
      },
    ],
  }

]);
root.render(
  <React.StrictMode>
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENTID}>
        <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/simple-line-icons/2.5.5/css/simple-line-icons.min.css"/>
        <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap-icons@1.11.3/font/bootstrap-icons.min.css"/>
        <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200" />
        <link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet"></link>
        <link href="https://fonts.googleapis.com/icon?family=Material+Icons+Outlined" rel="stylesheet"></link>
        <RouterProvider router={router} />
    </GoogleOAuthProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
