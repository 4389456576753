import React from 'react'
import Nav from './component/Nav';
import { Outlet } from 'react-router-dom'
import Menu from './component/Menu';

const Layout = () => {
  return (
    <>
    <Nav/>
    <div className="row m-0">
      <Menu/>
      <Outlet/>
    </div>
    </>
  )
}

export default Layout;
