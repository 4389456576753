import React from 'react'
import {Helmet} from "react-helmet";
import PropType from 'prop-types'
const Head = ({title, description}) => {
  return (
    <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
    </Helmet>
  )
}

Head.PropType = {
    title: PropType.string.isRequired,
    description: PropType.string.isRequired
}

// Head.defaultProps = {
//     title: process.env.REACT_APP_TITLE,
//     description: process.env.REACT_APP_DESCRIPTION
// }

export default Head; 