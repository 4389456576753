import React , { useEffect, useState } from "react";
import { Link } from 'react-router-dom'
import Head from "../component/Head"

const AboutUs = () => {
    
    const [Categories, setCategories] = useState([
        {
            images: '/product/p1.png',
            title: 'Tenniscore'
        },
        {
            images: '/product/p2.png',
            title: 'Art & Collectibles'
        },
        {
            images: '/product/p3.png',
            title: 'Bags & Purses'
        },
        {
            images: '/product/p4.png',
            title: 'Bath & Beauty'
        },
        {
            images: '/product/p5.png',
            title: 'Jewellery'
        },
        {
            images: '/product/p6.png',
            title: 'Home & Living'
        },
        {
            images: '/product/p1.png',
            title: 'Tenniscore'
        },
        {
            images: '/product/p2.png',
            title: 'Art & Collectibles'
        },
        {
            images: '/product/p3.png',
            title: 'Bags & Purses'
        },
        {
            images: '/product/p4.png',
            title: 'Bath & Beauty'
        },
        {
            images: '/product/p5.png',
            title: 'Jewellery'
        },
        {
            images: '/product/p6.png',
            title: 'Home & Living'
        }
    ]);

  return (
    <>
    <Head title={'Category'} description={"text"}/>
    <div className="container mt-5 d-flex justify-content-center align-items-center p-5 rounded-3 bg-category" style={{backgroundImage:`url('/category banner.png')`}}>
        <div className="h3">
        About Us
        </div>
    </div>
    <div className="container mt-4">
        <div className="col-12 text-center fs-5 fw-bold mb-4">
        Welcome to Tulo!
        </div>
        <div className="col-12 text-secondary">
        <p>At Tulo, we believe in the power of community and the beauty of handmade, local products. Our journey began with a simple yet powerful vision: to create a platform that seamlessly connects customers with unique, high-quality products crafted by small business owners and home businesses.
        </p>
        <p>
We understand the challenges faced by small business owners in reaching a wider audience and managing logistics. That’s why we’ve made it our mission to provide a comprehensive solution that benefits both vendors and customers. With Tulo, business owners can showcase their creations to the masses and reach customers pan India without worrying about shipping and logistics – we handle it all for them.
</p>
<p>
Our platform is designed to be a vibrant marketplace for anything and everything these talented businesses have to offer. We bring a diverse array of local treasures to your doorstep. By shopping with Tulo, you’re not just purchasing a product, you’re supporting the dreams and passions of countless small business owners.
</p>
<p>
Join us on this exciting journey and discover that Tulo is more than a marketplace – it’s a community that celebrates creativity, quality, and the entrepreneurial spirit.
</p>
<p>
Thank you for being a part of Tulo!</p>
        </div>
    </div>
    <div className="col-12 mb-5">
        <marquee behavior="scroll" direction="left">
            <img src="/product/p1.png" alt="" className="rounded-0 m-1" height={200} width={'40%'} />
            <img src="/product/p2.png" alt="" className="rounded-0 m-1" height={200} width={'40%'} />
            <img src="/product/p3.png" alt="" className="rounded-0 m-1" height={200} width={'40%'} />
            <img src="/product/p4.png" alt="" className="rounded-0 m-1" height={200} width={'40%'} />
            <img src="/product/p5.png" alt="" className="rounded-0 m-1" height={200} width={'40%'} />
            <img src="/product/p6.png" alt="" className="rounded-0 m-1" height={200} width={'40%'} />
        </marquee>
    </div>
    </>
  )
}


export default AboutUs
