import React , { useEffect, useState } from "react";
import { Link } from 'react-router-dom'
import { IoCloseOutline } from "react-icons/io5";
import { MdKeyboardArrowDown } from "react-icons/md";

const Cart = ({cartState , setCartState , cartProduct, setCartProduct}) => {
    console.log(cartProduct);
    
    const [order, setOrder] = useState(null)
    

    useEffect(() => {
        return async () => {
            console.log('fetching ...',`https://dummyjson.com/products`);
          try {
           
                let dataJson = await fetch(`https://dummyjson.com/products`).then((res)=> res.json());
                // countDiv = await data.json();
                dataJson = dataJson.products;
                dataJson = cartProduct.reduce((acc, id) => {
                    // Check if the product is already in the cart
                    const existingProduct = acc.find(product => product.id === id);
                
                    if (existingProduct) {
                      // If already in the cart, increase the quantity
                      existingProduct.quantity += 1;
                    } else {
                      // Find the product details in the products array
                      const product = dataJson.find(product => product.id === id);
                      if (product) {
                        // Add product to the cart with initial quantity of 1
                        acc.push({ ...product, quantity: 1 });
                      }
                    }
                
                    return acc;
                  }, []);
                
                setOrder(dataJson);
                // let dataJson2 = await fetch('https://dummyjson.com/products?limit=12').then((res)=> res.json());
                // // countDiv = await data.json();
                // dataJson2 = dataJson2.products;
                // setCategories(dataJson2);
         
            } catch (error) {
                console.error('Error fetching property:', error);
          }
            }
           
    }, [cartProduct])
    const inputHandler = (e) => {
        console.log(e);
        
    }
  return (
    <div className={`position-fixed z-top-1 top-0 bg-white p-4 shadow cart ${cartState ? 'active' : ''}`}>
      <div className="col-12">
        <div className="row m-0 align-items-center justify-content-between py-1 mb-0">
            <div className="col-auto">
            Cart
            </div>
            <IoCloseOutline onClick={()=>setCartState(false)} className='col-auto fs-5'/>
        </div>
            <hr style={{backgroundColor:'#f8f8f8 !important'}} />
           <p> You are eligible for free shipping.</p>
            <div className="row m-0">
                {order && order.map((e,i)=><div key={i} className="col-12 mb-0 border-bottom border-1 rounded-0 p-2 py-3 position-relative" style={{borderColor: '#e8e8e8 !important'}}>
                    <div className="row m-0 px-0">
                        <div className="ps-0 px-1 col-auto">
                            <img src={e.images[0]} alt="" className="w-auto rounded-3" height={80} />
                        </div>
                        <div className="pe-0 px-1 col">
                            <div className="col-12 mb-1 fs-7">
                                <div className="row m-0 justify-content-between w-100">
                                    <span className="fs-8 text-truncate col-8 px-0">{e.title}</span>
                                    <IoCloseOutline className="w-fit p-0"/>
                                </div>
                            </div>
                            <div className="col-12 mb-2 fs-9">
                                <div className="d-flex justify-content-between w-100">
                                    <div>
                                        <span className="text-secondary">Total :</span> <strong className="text-black">Rs.{e && ((e.discountPercentage/100)*20 + e.price). toFixed(2)}</strong> 
                                    </div>
                                    {/* <span className="text-black fs-9">On {e.delivery}</span> */}
                                </div>
                            </div>
                            <div className="col-12 px-0 fs-9 d-flex align-items-center">
                                <label htmlFor="Qty" className="form-label">Qty<sup className="text-danger">*</sup> :</label>
                                <div className="input-group input-group-sm ms-2" style={{width: '80px'}}>
                                    <span className="input-group-text py-0 ">-</span>
                                    <input type="text" className=" py-0 form-control text-center fs-8"  aria-label="Amount (to the nearest rupee)" onChange={(e)=>inputHandler(e)} value={e.quantity}/>
                                    <span className="input-group-text py-0 ">+</span>
                                </div>
                                <div className="d-flex ms-3">
                                    <span className="text-secondary">Color :</span>
                                    <span className="text-black">{e.color}</span>
                                </div>
                                <div className="d-flex ms-3">
                                    <span className="text-secondary">Size :</span>
                                    <span className="text-black">{e.Size} <MdKeyboardArrowDown/></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>)}
            </div>
      </div>
    </div>
  )
}

export default Cart
