import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { Link, useNavigate } from 'react-router-dom';
import { GoogleLogin } from '@react-oauth/google';
import FacebookLogin from 'react-facebook-login';

const Login = ({loginState , setLoginState}) => {
    const navigate = useNavigate()
    let buttonVisible =  useRef();
    let PasswordVisible =  useRef();
    let EmailBox = useRef();
    const [Email, setEmail] = useState('');
    const [Password, setPassword] = useState('');
    const [IsValid, setIsValid] = useState(0);
    const changeVisibility = () => {
        buttonVisible.current.innerHTML = `<span className="material-icons">` + (buttonVisible.current.innerText === 'visibility' ? 'visibility_off' : 'visibility') + '</span>';
        PasswordVisible.current.setAttribute('type', (buttonVisible.current.innerText === 'visibility_off' ? 'text' : 'password'))
    }
    function isValidEmail(email) {
        const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
        return emailRegex.test(email);
    }
    const checkEmail = (Email) => {
        setEmail(Email);
        if(Email !== "" && !isValidEmail(Email)){
            EmailBox.current.classList.add('border-danger');
            EmailBox.current.setAttribute('placeholder','Enter Valid Email ID');
            setIsValid(1);
        }else{
            EmailBox.current.classList.remove('border-danger');
            EmailBox.current.classList.add('border-success');
            EmailBox.current.setAttribute('placeholder','Enter Email ID');
            setIsValid(0);
        }
    }  
    const checkPassword = (Password) => {
        setPassword(Password);
        // Validate lowercase letters
        if(/^(?=.{8,})(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[\W])/.test(Password)) {
            PasswordVisible.current.classList.remove("border-danger");
            PasswordVisible.current.classList.add("border-success");
            setIsValid(1);
        } else {
            PasswordVisible.current.classList.remove("border-success");
            PasswordVisible.current.classList.add("border-danger");
            setIsValid(0);
        }
    }

    const handleSuccess = (credentialResponse) => {
        // If you are using the authorization code flow, you will receive a code to be exchanged for an access token
        const authorizationCode = credentialResponse.code;
    
        // Send the authorization code to your backend server
        fetch('/api/auth/google', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ code: authorizationCode }),
        })
        .then(response => response.json())
        .then(data => {
          // Handle the response from your backend server
          console.log('Login successful, backend response:', data);
        })
        .catch(error => {
          // Handle errors in communicating with your backend server
          console.error('Error exchanging authorization code:', error);
        });
      };
      
      const handleError = (errorResponse) => {
        console.error('Google login failed', errorResponse);
      };
      const responseFacebook = (response) => {
        console.log(response);
        // Handle the response here, e.g., send it to your server for verification
      };
    
    const submitFrom = () => {
        if (IsValid) {
            setLoginState(false)
            navigate('/profile')
        }
    }


    const popupRef = useRef(null);
  
    const handleClickOutside = (event) => {
      // Check if the click was outside the popup
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        setLoginState(false);
      }
    };
  
    useEffect(() => {
      // Add event listener for clicks outside the popup
      if (loginState) {
        document.addEventListener("mousedown", handleClickOutside);
      } else {
        document.removeEventListener("mousedown", handleClickOutside);
      }
  
      // Cleanup the event listener
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [loginState]);
  


    return (<>
        {loginState && (
            <div className={`login  ${loginState ? 'active' : ''} col-12 position-fixed justify-content-center align-items-center top-0 start-0 h-100 w-100 bg-black bg-opacity-50 z-top-2`}>

                <div ref={popupRef} className="col-10 col-md-10 col-lg-8 px-0 bg-white d-flex justify-content-center align-items-center" style={{height: '80vh'}}>
                    <div className="row m-0 w-100 h-100 overflow-hidden position-relative h-100">
                        <div className="col-12 col-md-6 col-md overflow-y-auto d-flex justify-content-center align-items-center h-100 m-0 align-items-center h-fit">
                            <div className="col-10 d-flex flex-column">
                                <div className='text-black fw-semibold text-center h3 mb-1 h-fit'>Sign In To Your Account</div>
                                <p className='mb-4 fs-8 text-secondary text-center'>Nullam risus blandit ac aliquam justo ipsum</p>
                                <form action="" method="post" id="login" className='col-12'>
                                    <div className="mb-3">
                                        {/* <label htmlFor="formGroupExampleInput" className="form-label">Email</label> */}
                                        <input type="email" className="form-control fs-7 placeholder-light" ref={EmailBox} onChange={(e)=>checkEmail(e.target.value)} placeholder="Email" />
                                    </div>
                                    <div className="mb-3">
                                        {/* <label htmlFor="formGroupExampleInput2" className="form-label">Password</label> */}
                                        <div className="input-group">
                                            <input type="password" ref={PasswordVisible} className="form-control fs-7 placeholder-light" onChange={(e)=>checkPassword(e.target.value)}  placeholder="Password" />
                                            <span ref={buttonVisible} className="input-group-text " id="visibility" onClick={changeVisibility}>
                                                <span className="material-icons fs-6">
                                                visibility
                                                </span>
                                            </span>
                                        </div>
                                    </div>
                                    <div className="row m-0 justify-content-between align-items-center mt-4">
                                    <button type="button" onClick={submitFrom} className="btn w-100 btn-theme rounded-2 mb-3 px-5 py-2" >
                                        Sign in
                                    </button>
                                    <Link className="text-theme text-decoration-none text-center mt-0 mb-2 fs-8" to="/forget" role="button" >Forget password?</Link>
                                    <p className='text-center fs-8'>Don’t have an account? <Link className="text-theme text-decoration-none  mt-2" to="/forget" role="button" > Create account</Link></p>
                                    
                                    <div className="col-12 text-center my-0">
                                        <p className='text-secondary mb-0 fw-normal'>--------- OR ---------</p>
                                    </div>
                                    <div className="col-12 row m-0 mt-2 g-2">
                                        <div className="col-6 mb-0 ps-0">
                                            <GoogleLogin onSuccess={handleSuccess} onError={handleError} useOneTap flow="auth-code"/>
                                        </div>
                                        <div className="col-6 mb-0 pe-0">
                                            <FacebookLogin appId={process.env.REACT_APP_FACEBOOK_APP_ID} autoLoad={false} fields="name,email,picture" callback={responseFacebook} icon="fa-facebook" textButton="Facebook" />
                                        </div>
                                    </div>
                                    </div>
                                    
                                </form>
                            </div>
                        </div>
                        <div className="col-12 col-md-6 overflow-hidden p-0 h-100">
                            <img src="/loginbackground.png" alt=""  className='w-100 h-100 object-fit-cover' />
                        </div>
                    </div>
                </div>
            </div>
        )}
    </>
    )
}

// Login.propTypes = {

// }

export default Login
