import React, { useState, useEffect, useRef } from "react";
import Slider from "react-slick";
import { Link, useParams, useOutletContext } from 'react-router-dom'
import Head from "../component/Head"
import Card from "../component/Card"
import { RiHeartLine, RiStarSFill, RiMessage2Line, RiTruckLine } from "react-icons/ri";
import { TfiLayoutGrid2Alt, TfiLayoutGrid3Alt } from "react-icons/tfi";
import { MdKeyboardArrowDown } from "react-icons/md";

const Search = () => {
    const { cartState, setCartState , cartProduct, setCartProduct} = useOutletContext();
    console.log(cartState, setCartState);
    const { category } = useParams(1)
    console.log(category);
    const [toggle, setToggle] = useState(false)
    const [data, setData] = useState([]);
    const [filter, setFilter] = useState(false)
    useEffect(() => {
        return async () => {
            let dataJson = await fetch('https://dummyjson.com/products?limit=9').then((res) => res.json());
            // countDiv = await data.json();
            dataJson = dataJson.products;
            setData(dataJson);
            // let dataJson2 = await fetch('https://dummyjson.com/products?limit=12').then((res)=> res.json());
            // // countDiv = await data.json();
            // dataJson2 = dataJson2.products;
            // setCategories(dataJson2);
        }
    }, [])

    const inputHandler = (e) => {
        console.log(e);

    }
    return (
        <>
            <Head title={'Product'} description={"text"} />
            <div className="container py-2 mt-3 px-md-0">
                <nav className="breadcrumb fs-8">
                    <Link className="breadcrumb-item text-decoration-none text-black" to="#">Home Decor</Link>
                    <span className="breadcrumb-item active text-theme" aria-current="page">Art & Collectibles</span>
                </nav>
            </div>
            <div className="container py-2 px-md-0 d-block d-md-none">
                <h2 className="w-100 text-center">Art & Collectibles</h2>
            </div>
            <div className="container py-2 px-md-0">
                <div className="row m-0 justify-content-between align-items-center fs-sm-6">
                    <div className="col-auto px-0">
                        <span onClick={() => { setFilter(!filter) }}>Filter</span>
                    </div>
                    <div className="col-auto px-0 d-none d-md-block">
                        <TfiLayoutGrid2Alt onClick={() => setToggle(!toggle)} className={`m-1 ${toggle ? '' : 'text-secondary'}`} />
                        <TfiLayoutGrid3Alt onClick={() => setToggle(!toggle)} className={`m-1 ${!toggle ? '' : 'text-secondary'}`} />
                    </div>
                    <div className="col-auto px-0 d-none d-md-block">
                        <h2>Art & Collectibles</h2>
                    </div>
                    <div className="col-auto px-0">
                        <div className="d-flex align-items-center fs-8">
                            Sort by: &nbsp;<strong>Featured</strong>
                            <MdKeyboardArrowDown />
                        </div>
                    </div>
                </div>
            </div>
            {
                !category ?
                    <div className="container py-2 px-md-0">
                        <img src="/banner.png" alt="" className="w-100 px-0" />
                    </div>
                    :
                    ''

            }
            <div className="container py-2 px-md-0 mt-3 mt-md-4 mb-4 mb-md-5">
                <div className="row m-0">
                    <div className={`col-12 col-md-3 px-md-0 filter ${filter ? 'active' : ' '}`}>
                        <div className="col-12 row m-0 justify-content-between align-items-center">
                            <span className="col-auto px-0 fs-5 fw-light">Filter by</span>
                            <Link href="http://" target="_blank" rel="noopener noreferrer" className="col-auto text-theme fs-9 fw-light">Clear all</Link>
                        </div>
                        <div className="accordion accordion-flush mt-4" id="accordionSearch">
                            <div className="accordion-item accordion-custom">
                                <h2 className="accordion-header">
                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                                        Category
                                    </button>
                                </h2>
                                <div id="flush-collapseOne" className="accordion-collapse collapse" data-bs-parent="#accordionSearch">
                                    <div className="accordion-body">
                                        <div className="row m-0">
                                            <div className="form-check">
                                                <input className="form-check-input" type="checkbox" onChange={(e) => inputHandler(e)} value="" id="" />
                                                <label className="form-check-label" htmlFor=""> Art & Collectibles </label>
                                            </div>
                                            <div className="form-check">
                                                <input className="form-check-input" type="checkbox" onChange={(e) => inputHandler(e)} value="" id="" />
                                                <label className="form-check-label" htmlFor=""> Bags & Purses </label>
                                            </div>
                                            <div className="form-check">
                                                <input className="form-check-input" type="checkbox" onChange={(e) => inputHandler(e)} value="" id="" />
                                                <label className="form-check-label" htmlFor=""> Bath & Beauty </label>
                                            </div>
                                            <div className="form-check">
                                                <input className="form-check-input" type="checkbox" onChange={(e) => inputHandler(e)} value="" id="" />
                                                <label className="form-check-label" htmlFor=""> Home & Living </label>
                                            </div>
                                            <div className="form-check">
                                                <input className="form-check-input" type="checkbox" onChange={(e) => inputHandler(e)} value="" id="" />
                                                <label className="form-check-label" htmlFor=""> Prints </label>
                                            </div>
                                            <div className="form-check">
                                                <input className="form-check-input" type="checkbox" onChange={(e) => inputHandler(e)} value="" id="" />
                                                <label className="form-check-label" htmlFor=""> What’s new </label>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                               
                            </div>
                            <div className="accordion-item accordion-custom">
                                <h2 className="accordion-header">
                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                                        Price
                                    </button>
                                </h2>
                                <div id="flush-collapseTwo" className="accordion-collapse collapse" data-bs-parent="#accordionSearch">
                                    <div className="accordion-body">Placeholder content for this accordion, which is intended to demonstrate the <code>.accordion-flush</code> class. This is the second item's accordion body. Let's imagine this being filled with some actual content.</div>
                                </div>
                            </div>
                            <div className="accordion-item accordion-custom">
                                <h2 className="accordion-header">
                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                                        Verified seller
                                    </button>
                                </h2>
                                <div id="flush-collapseThree" className="accordion-collapse collapse" data-bs-parent="#accordionSearch">
                                    <div className="accordion-body">Placeholder content for this accordion, which is intended to demonstrate the <code>.accordion-flush</code> class. This is the third item's accordion body. Nothing more exciting happening here in terms of content, but just filling up the space to make it look, at least at first glance, a bit more representative of how this would look in a real-world application.</div>
                                </div>
                            </div>
                            <div className="accordion-item accordion-custom">
                                <h2 className="accordion-header">
                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFoure" aria-expanded="false" aria-controls="flush-collapseFoure">
                                        Delivery Day
                                    </button>
                                </h2>
                                <div id="flush-collapseFoure" className="accordion-collapse collapse" data-bs-parent="#accordionSearch">
                                    <div className="accordion-body">Placeholder content for this accordion, which is intended to demonstrate the <code>.accordion-flush</code> class. This is the third item's accordion body. Nothing more exciting happening here in terms of content, but just filling up the space to make it look, at least at first glance, a bit more representative of how this would look in a real-world application.</div>
                                </div>
                            </div>
                            <div className="accordion-item accordion-custom">
                                <h2 className="accordion-header">
                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseSix" aria-expanded="false" aria-controls="flush-collapseSix">
                                        Customisable
                                    </button>
                                </h2>
                                <div id="flush-collapseSix" className="accordion-collapse collapse" data-bs-parent="#accordionSearch">
                                    <div className="accordion-body">Placeholder content for this accordion, which is intended to demonstrate the <code>.accordion-flush</code> class. This is the third item's accordion body. Nothing more exciting happening here in terms of content, but just filling up the space to make it look, at least at first glance, a bit more representative of how this would look in a real-world application.</div>
                                </div>
                            </div>
                            <div className="accordion-item accordion-custom">
                                <h2 className="accordion-header">
                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseSeven" aria-expanded="false" aria-controls="flush-collapseSeven">
                                        Colors
                                    </button>
                                </h2>
                                <div id="flush-collapseSeven" className="accordion-collapse collapse" data-bs-parent="#accordionSearch">
                                    <div className="accordion-body">Placeholder content for this accordion, which is intended to demonstrate the <code>.accordion-flush</code> class. This is the third item's accordion body. Nothing more exciting happening here in terms of content, but just filling up the space to make it look, at least at first glance, a bit more representative of how this would look in a real-world application.</div>
                                </div>
                            </div>
                            <div className="accordion-item accordion-custom">
                                <h2 className="accordion-header">
                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseEight" aria-expanded="false" aria-controls="flush-collapseEight">
                                        Eligible for Pay on Delivery
                                    </button>
                                </h2>
                                <div id="flush-collapseEight" className="accordion-collapse collapse" data-bs-parent="#accordionSearch">
                                    <div className="accordion-body">Placeholder content for this accordion, which is intended to demonstrate the <code>.accordion-flush</code> class. This is the third item's accordion body. Nothing more exciting happening here in terms of content, but just filling up the space to make it look, at least at first glance, a bit more representative of how this would look in a real-world application.</div>
                                </div>
                            </div>
                            <div className="accordion-item accordion-custom">
                                <h2 className="accordion-header">
                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseNight" aria-expanded="false" aria-controls="flush-collapseNight">
                                        Exclude Out of Stock
                                    </button>
                                </h2>
                                <div id="flush-collapseNight" className="accordion-collapse collapse" data-bs-parent="#accordionSearch">
                                    <div className="accordion-body">Placeholder content for this accordion, which is intended to demonstrate the <code>.accordion-flush</code> class. This is the third item's accordion body. Nothing more exciting happening here in terms of content, but just filling up the space to make it look, at least at first glance, a bit more representative of how this would look in a real-world application.</div>
                                </div>
                            </div>
                        </div>
                        {filter ?
                                    <div class="row row-cols-2 g-4 m-0">
                                        <div className="col">
                                        <button type="button" name="" id="" class="btn w-100 btn-theme" >
                                            Apply Filter
                                        </button>
                                        </div>
                                        <div className="col">

                                        <button type="button" name="" id="" class="btn w-100 btn-outline-theme" onClick={() => { setFilter(!filter) }}>
                                            Close
                                        </button>
                                        </div>
                                    </div>

                                    : ' '}
                    </div>
                    <div className="col-12 col-md-9 px-0 px-md-2">
                        <div className="d-flex flex-wrap h-100">
                            {data && data.map((e) => {
                                return <Card cartState={cartState} classN={`col-6 ${toggle} ${toggle ? 'col-md-6' : 'col-md-4'} p-2`} setCartState={setCartState} cartProduct={cartProduct} setCartProduct={setCartProduct} data={e} />
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Search
