import React, { useState, useEffect, useRef } from "react";
import Slider from "react-slick";
import { Link, useParams ,useOutletContext } from 'react-router-dom'
import Head from "../component/Head"
import Card from "../component/Card"
import { RiHeartLine , RiStarSFill , RiMessage2Line , RiTruckLine  ,RiStarHalfSFill  ,RiStarSLine   } from "react-icons/ri";
import { TfiLayoutGrid2Alt , TfiLayoutGrid3Alt  } from "react-icons/tfi";
import { MdKeyboardArrowDown } from "react-icons/md";

const Shop = () => {
    const {shopid} = useParams(1)
    console.log(shopid);
    
    const {cartState , setCartState , cartProduct, setCartProduct} = useOutletContext();
    const [Categories, setCategories] = useState([
        {
            images: '/product/p1.png',
            title: 'Tenniscore'
        },
        {
            images: '/product/p2.png',
            title: 'Art & Collectibles'
        },
        {
            images: '/product/p3.png',
            title: 'Bags & Purses'
        },
        {
            images: '/product/p4.png',
            title: 'Bath & Beauty'
        },
        {
            images: '/product/p5.png',
            title: 'Jewellery'
        },
        {
            images: '/product/p6.png',
            title: 'Home & Living'
        },
        {
            images: '/product/p1.png',
            title: 'Tenniscore'
        },
        {
            images: '/product/p2.png',
            title: 'Art & Collectibles'
        },
        {
            images: '/product/p3.png',
            title: 'Bags & Purses'
        },
        {
            images: '/product/p4.png',
            title: 'Bath & Beauty'
        },
        {
            images: '/product/p5.png',
            title: 'Jewellery'
        },
        {
            images: '/product/p6.png',
            title: 'Home & Living'
        }
    ]);

    const [data, setData] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
          try {
            const response = await fetch('https://dummyjson.com/products?limit=9');
            const dataJson = await response.json();
            setData(dataJson.products);
            console.log(dataJson);
            
          } catch (error) {
            console.error("Failed to fetch products", error);
          }
        };
    
        // Fetch data only if data is null
        if (data === null) {
          fetchData();
        }
      }, [data]);
      
      
      const inputHandler = (e) => {
        console.log(e);
        
    }
  return (
    <>
       <Head title={'Product'} description={"text"}/>
       <div className="col-12 container px-0 py-4 ">
        <div className="row m-0 justify-content-between">
           <div className="col-auto px-0 row">
                <div className="col-auto">
                    <img src="/madstudio.png" alt="" className="w-auto shadow" height={110}/>
                </div>
                <div className="col">
                    <div className="col-auto fs-5 fw-bold mb-2">
                        {shopid}
                    </div>
                    <p className="mb-0">Beautiful Silver Handmade Jewelry</p>
                    <p className="fs-8 mb-2">Jaipur, Rajasthan</p>
                    <div className="d-flex justify-content-between m-0 px-0 w-fit fs-8">
                        <div className="">4.5 <RiStarSFill className="text-theme"/></div>
                        <div className="px-2">|</div>
                        <div className="">92,021 Ratings</div>
                        <div className="px-2">|</div>
                        <div className="">27 Products</div>
                    </div>
                </div>
           </div>
           <div className="col-auto px-0">
                <p>6,000 Followers</p>
                    <button type="button" name="" id="" className="btn btn-outline-dark btn-sm rounded-5">
                    <RiHeartLine/> Follow
                    </button>
                
           </div>
        </div>
       </div>
        <div className="container py-2 px-0">
            <div className="row m-0 justify-content-between">
                <div className="col-auto px-0">
                    <TfiLayoutGrid2Alt className="m-1"/>
                    <TfiLayoutGrid3Alt className="m-1 text-secondary"/> 
                </div>
                <div className="col-auto px-0">
                    <div className="d-flex align-items-center fs-8">
                    Sort by: &nbsp;<strong>Featured</strong>
                    <MdKeyboardArrowDown/>
                    </div>
                </div>
            </div>
        </div>
        <div className="container py-2 px-0 ">
            <div className="row m-0">
                <div className="col-12 col-md-3">
                    <div className="col-12 row m-0 justify-content-between">
                        <span className="col-auto">Filter by</span>
                        <Link href="http://" target="_blank" rel="noopener noreferrer" className="col-auto text-theme fs-9">Clear all</Link>
                    </div>
                    <div className="accordion accordion-flush" id="accordionFlushExample">
                        <div className="accordion-item accordion-custom">
                            <h2 className="accordion-header">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                                Category
                            </button>
                            </h2>
                            <div id="flush-collapseOne" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                            <div className="accordion-body">
                                <div className="row m-0">
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" onChange={(e)=>inputHandler(e)} value="" id="" />
                                        <label className="form-check-label" htmlFor=""> Art & Collectibles </label>
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" onChange={(e)=>inputHandler(e)} value="" id="" />
                                        <label className="form-check-label" htmlFor=""> Bags & Purses </label>
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" onChange={(e)=>inputHandler(e)} value="" id="" />
                                        <label className="form-check-label" htmlFor=""> Bath & Beauty </label>
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" onChange={(e)=>inputHandler(e)} value="" id="" />
                                        <label className="form-check-label" htmlFor=""> Home & Living </label>
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" onChange={(e)=>inputHandler(e)} value="" id="" />
                                        <label className="form-check-label" htmlFor=""> Prints </label>
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" onChange={(e)=>inputHandler(e)} value="" id="" />
                                        <label className="form-check-label" htmlFor=""> What’s new </label>
                                    </div>
                                    
                                </div>
                            </div>
                            </div>
                        </div>
                        <div className="accordion-item accordion-custom">
                            <h2 className="accordion-header">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                            Price
                            </button>
                            </h2>
                            <div id="flush-collapseTwo" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                            <div className="accordion-body">Placeholder content for this accordion, which is intended to demonstrate the <code>.accordion-flush</code> class. This is the second item's accordion body. Let's imagine this being filled with some actual content.</div>
                            </div>
                        </div>
                        <div className="accordion-item accordion-custom">
                            <h2 className="accordion-header">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFour" aria-expanded="false" aria-controls="flush-collapseThree">
                            Verified seller
                            </button>
                            </h2>
                            <div id="flush-collapseThree" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                            <div className="accordion-body">Placeholder content for this accordion, which is intended to demonstrate the <code>.accordion-flush</code> class. This is the third item's accordion body. Nothing more exciting happening here in terms of content, but just filling up the space to make it look, at least at first glance, a bit more representative of how this would look in a real-world application.</div>
                            </div>
                        </div>
                        <div className="accordion-item accordion-custom">
                            <h2 className="accordion-header">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFoure" aria-expanded="false" aria-controls="flush-collapseFoure">
                            Delivery Day
                            </button>
                            </h2>
                            <div id="flush-collapseFoure" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                            <div className="accordion-body">Placeholder content for this accordion, which is intended to demonstrate the <code>.accordion-flush</code> class. This is the third item's accordion body. Nothing more exciting happening here in terms of content, but just filling up the space to make it look, at least at first glance, a bit more representative of how this would look in a real-world application.</div>
                            </div>
                        </div>
                        <div className="accordion-item accordion-custom">
                            <h2 className="accordion-header">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseSix" aria-expanded="false" aria-controls="flush-collapseSix">
                            Customisable
                            </button>
                            </h2>
                            <div id="flush-collapseSix" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                            <div className="accordion-body">Placeholder content for this accordion, which is intended to demonstrate the <code>.accordion-flush</code> class. This is the third item's accordion body. Nothing more exciting happening here in terms of content, but just filling up the space to make it look, at least at first glance, a bit more representative of how this would look in a real-world application.</div>
                            </div>
                        </div>
                        <div className="accordion-item accordion-custom">
                            <h2 className="accordion-header">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseSeven" aria-expanded="false" aria-controls="flush-collapseSeven">
                            Colors
                            </button>
                            </h2>
                            <div id="flush-collapseSeven" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                            <div className="accordion-body">Placeholder content for this accordion, which is intended to demonstrate the <code>.accordion-flush</code> class. This is the third item's accordion body. Nothing more exciting happening here in terms of content, but just filling up the space to make it look, at least at first glance, a bit more representative of how this would look in a real-world application.</div>
                            </div>
                        </div>
                        <div className="accordion-item accordion-custom">
                            <h2 className="accordion-header">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseEight" aria-expanded="false" aria-controls="flush-collapseEight">
                            Eligible for Pay on Delivery
                            </button>
                            </h2>
                            <div id="flush-collapseEight" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                            <div className="accordion-body">Placeholder content for this accordion, which is intended to demonstrate the <code>.accordion-flush</code> class. This is the third item's accordion body. Nothing more exciting happening here in terms of content, but just filling up the space to make it look, at least at first glance, a bit more representative of how this would look in a real-world application.</div>
                            </div>
                        </div>
                        <div className="accordion-item accordion-custom">
                            <h2 className="accordion-header">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseNight" aria-expanded="false" aria-controls="flush-collapseNight">
                            Exclude Out of Stock
                            </button>
                            </h2>
                            <div id="flush-collapseNight" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                            <div className="accordion-body">Placeholder content for this accordion, which is intended to demonstrate the <code>.accordion-flush</code> class. This is the third item's accordion body. Nothing more exciting happening here in terms of content, but just filling up the space to make it look, at least at first glance, a bit more representative of how this would look in a real-world application.</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-md-9">
                    <div className="d-flex flex-wrap h-100">
                        {data && data.map((e) => {
                            return <Card cartState={cartState} setCartState={setCartState} data={e} cartProduct={cartProduct} setCartProduct={setCartProduct} classN={'col-6 col-md-4 p-2'} />
                        })}
                    </div>
                </div>
            </div>
        </div>
        <div className="container py-2 px-0 mb-5 mt-3">
            <div className="col-12 row m-0">
                <div className="col-12 fw-medium h4 mb-4">
                Customer Reviews (14)
                </div>
                <div className="col-12">
                    <div className="col-12">
                        <div className="col d-flex align-items-center p-2 bg-custom-light mb-3">
                            Filter By: 
                                <select className="form-select form-select-sm w-auto ms-2" name="" id="" >
                                    <option selected>Reating</option>
                                    <option onChange={(e)=>inputHandler(e)} value="">New Delhi</option>
                                    <option onChange={(e)=>inputHandler(e)} value="">Istanbul</option>
                                    <option onChange={(e)=>inputHandler(e)} value="">Jakarta</option>
                                </select>
                                <select className="form-select form-select-sm w-auto ms-2" name="" id="" >
                                    <option selected>Review with image</option>
                                    <option onChange={(e)=>inputHandler(e)} value="">New Delhi</option>
                                    <option onChange={(e)=>inputHandler(e)} value="">Istanbul</option>
                                    <option onChange={(e)=>inputHandler(e)} value="">Jakarta</option>
                                </select>
                            
                        </div>
                        <div className="col-12 row row-cols-2 g-2 m-0 align-items-center px-0 p-1">
                            <div className="col-12">
                                <div className="col-12 p-3">
                                    <div className="col-12 mb-2 px-0">
                                        <div className="px-0 row m-0 g-1 w-fit">
                                            <RiStarSFill className="mt-0 w-auto text-theme"/>
                                            <RiStarSFill className="mt-0 w-auto text-theme"/>
                                            <RiStarSFill className="mt-0 w-auto text-theme"/>
                                            <RiStarSFill className="mt-0 w-auto text-theme"/>
                                            <RiStarHalfSFill   className="mt-0 w-auto text-theme"/>
                                        </div>
                                    </div>
                                    <p className="mb-2">Absolutely beautiful- my husband loved it (for our 5th anniversary = wood) and we even got our date engraved on the back! And it came so well packaged! Looked exactly as</p>
                                    <div className="col-12 d-flex m-0 flex-column">
                                        <img src="/product/p2.png" alt="" className="mb-2" width={30} height={30} />
                                        <div className="col-12 d-flex m-0 justify-content-between">
                                            <div className="col-auto fs-9 w-auto">Simran Saini</div>
                                            <div className="col-auto fs-9 text-secondary w-auto">28 Feb, 2024</div>
                                        </div>
                                    </div>
                                </div>
                                <hr />
                            </div>
                            <div className="col-12">
                                <div className="col-12 p-3">
                                    <div className="col-12 mb-2 px-0">
                                        <div className="px-0 row m-0 g-1 w-fit">
                                            <RiStarSFill className="mt-0 w-auto text-theme"/>
                                            <RiStarSFill className="mt-0 w-auto text-theme"/>
                                            <RiStarSFill className="mt-0 w-auto text-theme"/>
                                            <RiStarSFill className="mt-0 w-auto text-theme"/>
                                            <RiStarHalfSFill   className="mt-0 w-auto text-theme"/>
                                        </div>
                                    </div>
                                    <p className="mb-2">Absolutely beautiful- my husband loved it (for our 5th anniversary = wood) and we even got our date engraved on the back! And it came so well packaged! Looked exactly as</p>
                                    <div className="col-12 d-flex m-0 flex-column">
                                        {/* <img src="/product/p2.png" alt="" className="mb-2" width={30} height={30} /> */}
                                        <div className="col-12 d-flex m-0 justify-content-between">
                                            <div className="col-auto fs-9 w-auto">Simran Saini</div>
                                            <div className="col-auto fs-9 text-secondary w-auto">28 Feb, 2024</div>
                                        </div>
                                    </div>
                                </div>
                                <hr />
                            </div>
                            <div className="col-12">
                                <div className="col-12 p-3">
                                    <div className="col-12 mb-2 px-0">
                                        <div className="px-0 row m-0 g-1 w-fit">
                                            <RiStarSFill className="mt-0 w-auto text-theme"/>
                                            <RiStarSFill className="mt-0 w-auto text-theme"/>
                                            <RiStarSFill className="mt-0 w-auto text-theme"/>
                                            <RiStarSFill className="mt-0 w-auto text-theme"/>
                                            <RiStarHalfSFill   className="mt-0 w-auto text-theme"/>
                                        </div>
                                    </div>
                                    <p className="mb-2">Absolutely beautiful- my husband loved it (for our 5th anniversary = wood) and we even got our date engraved on the back! And it came so well packaged! Looked exactly as</p>
                                    <div className="col-12 d-flex m-0 flex-column">
                                        {/* <img src="/product/p2.png" alt="" className="mb-2" width={30} height={30} /> */}
                                        <div className="col-12 d-flex m-0 justify-content-between">
                                            <div className="col-auto fs-9 w-auto">Simran Saini</div>
                                            <div className="col-auto fs-9 text-secondary w-auto">28 Feb, 2024</div>
                                        </div>
                                    </div>
                                </div>
                                <hr />  
                            </div>
                        </div>
                    <div className="col"></div>
                </div>
                </div>
                <div className="col-12 mb-4">
                    <div className="h3 mb-3">Workshops Link</div>
                    <ul>
                        <li>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</li>
                        <li>Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</li>
                        <li>fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.   </li>
                    </ul>
                </div>
                <div className="col-12">
                    <div className="h3 mb-3">About Seller</div>
                    <p className="fs-8 fw-bold mb-2">Jewels By Aditi is made to give a little more color to your colorful life.</p>
                    <p className="fs-9">In a small corner of my home, surrounded by boxes filled with gemstones, beads, and tools, I found myself dreaming of something more. The desire to create jewelry that would touch people's lives burned within me, and I knew it was time to take a leap of faith. With trembling hands and a heart full of anticipation, I opened the virtual doors of my Etsy shop. I poured my passion an <strong>Read More</strong></p>
                </div>
                <div className="col-12">
                    <div className="h3 mb-3">Shop policies</div>
                    <div className="accordion accordion-flush" id="accordionFlushExample">
                        <div className="accordion-item">
                            <h2 className="accordion-header">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                            Returns & exchanges
                            </button>
                            </h2>
                            <div id="flush-collapseOne" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                            <div className="accordion-body">In case of return, we ensure quick refunds. Full amount will be refunded excluding  Convenience Fee</div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                            Delivery
                            </button>
                            </h2>
                            <div id="flush-collapseTwo" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                            <div className="accordion-body">Placeholder content for this accordion, which is intended to demonstrate the <code>.accordion-flush</code> class. This is the second item's accordion body. Let's imagine this being filled with some actual content.</div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                            Payment options
                            </button>
                            </h2>
                            <div id="flush-collapseThree" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                            <div className="accordion-body">Placeholder content for this accordion, which is intended to demonstrate the <code>.accordion-flush</code> class. This is the third item's accordion body. Nothing more exciting happening here in terms of content, but just filling up the space to make it look, at least at first glance, a bit more representative of how this would look in a real-world application.</div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFour" aria-expanded="false" aria-controls="flush-collapseFour">
                            Cancellations
                            </button>
                            </h2>
                            <div id="flush-collapseFour" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                            <div className="accordion-body">Placeholder content for this accordion, which is intended to demonstrate the <code>.accordion-flush</code> class. This is the third item's accordion body. Nothing more exciting happening here in terms of content, but just filling up the space to make it look, at least at first glance, a bit more representative of how this would look in a real-world application.</div>
                            </div>
                        </div>
                        </div>
                </div>
        </div>
        </div>
    </>
  )
}


export default Shop
