import React from 'react'
import { Link } from 'react-router-dom';

const Nav = () => {
  return (
    <div className="shadow px-4 py-3 d-flex justify-content-between" id="navBar">
        <div className="d-flex align-items-center">
          <span className="material-icons-outlined fs-5 me-3 text-secondary">
            menu
          </span>
          <Link to="/">
              <img loading="eager" src="/tulo.png" alt="logo" className="" height={22} />
          </Link>
        </div>
        <div className="d-flex align-items-center">
          <img src="/avtar.jpg" alt="" className=" rounded-5 overflow-hidden"  height={28} width={28}   />
        </div>
    </div>
  )
}

export default Nav;