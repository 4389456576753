import React from 'react'
import { Link } from 'react-router-dom'

const Footer = () => {
  return (
    <footer>
        <div className="container border-top py-5">
            <div className="row m-0 justify-content-center">
                <div className='text-center h2'>Be the first to hear about all things</div>
                <p className='fs-8 text-center'>Stay connected for exclusive offers and latest updates, delivered straight to your inbox</p>
                <div className="row col-12 col-md-8 mt-2 g-3">
                    <div className="col-9">
                        <input type="text" name="email" className='w-100 h-100 bg-light-theme border-0 px-4 py-3 rounded-2'  placeholder="Enter your email address" />
                    </div>
                    <div className="col-3">
                        <button className='btn btn-theme w-100 h-100 rounded-2'>Send</button>
                    </div>
                </div>
            </div>
            <div className="row m-0 py-4 mt-4">
                <div className="col-12 pb-3 pb-md-0 d-flex flex-column col-md">
                    <img src="/tulo.png" alt="" width={60} />
                    <p className='fs-9 mt-3'>Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
                </div>
                <div className="col-12 pb-3 pb-md-0 d-flex flex-column col-md">
                    <p className='fw-bold'>Brand Name</p>
                    <Link to="/" className="text-decoration-none text-dark fs-8 mb-2">Who we are</Link>
                    <Link to="/" className="text-decoration-none text-dark fs-8 mb-2">For your</Link>
                    <Link to="/" className="text-decoration-none text-dark fs-8 mb-2">Offers</Link>
                </div>
                <div className="col-12 pb-3 pb-md-0 d-flex flex-column col-md">
                    <p className='fw-bold'>Customer Care</p>
                    <Link to="/" className="text-decoration-none text-dark fs-8 mb-2">Help Center</Link>
                    <Link to="/" className="text-decoration-none text-dark fs-8 mb-2">FAQs</Link>
                    <Link to="/" className="text-decoration-none text-dark fs-8 mb-2">Offers</Link>
                </div>
                <div className="col-12 pb-3 pb-md-0 d-flex flex-column col-md">
                    <p className='fw-bold'>Categories</p>
                    <Link to="/" className="text-decoration-none text-dark fs-8 mb-2">Women's Clothes</Link>
                    <Link to="/" className="text-decoration-none text-dark fs-8 mb-2">Women's Accessories</Link>
                    <Link to="/" className="text-decoration-none text-dark fs-8 mb-2">Men's Clothes</Link>
                    <Link to="/" className="text-decoration-none text-dark fs-8 mb-2">Men's Accessories</Link>
                    <Link to="/" className="text-decoration-none text-dark fs-8 mb-2">Shop by Location</Link>
                    <Link to="/" className="text-decoration-none text-dark fs-8 mb-2">What's new - Geolocation</Link>
                </div>
                <div className="col-12 pb-3 pb-md-0 d-flex flex-column col-md">
                    <p className='fw-bold'>Contact Us</p>
                    <Link to="/" className="text-decoration-none text-dark fs-8 mb-2">Timing 10:00 AM - 6:30 PM (Mon - Sat)</Link>
                    <Link to="/" className="text-decoration-none text-dark fs-8 mb-2">Cell: 1-800 4788 1800</Link>
                    <Link to="/" className="text-decoration-none text-dark fs-8 mb-2">What's App 7800366393</Link>
                    <Link to="/" className="text-decoration-none text-dark fs-8 mb-2">Email: support@gmail.com</Link>
                </div>
            </div>
        </div>
    </footer>
  )
}

export default Footer
