import React from 'react'
import { Link } from 'react-router-dom'

import { RiShoppingCartLine , RiUserLine , RiHeartLine , RiMenuLine  ,RiHeartFill  } from "react-icons/ri";
import { PiShoppingCartSimpleFill } from "react-icons/pi";

function Card({cartState , setCartState , cartProduct, setCartProduct ,data, classN = 'col-6 col-md-3 p-2' , icon = 'outline'}) {
    
    let reviews = data.reviews.map((e)=>e.rating);
    const avg =
    (reviews.reduce((sum, curr) => sum + Number(curr), 0) /
    reviews.length).toFixed(1);

    const addCart = (id) =>{
        console.log(id);
        
        setCartState(true);
        let demo = cartProduct;
        demo.push(id);
        setCartProduct([...demo])
    }

  return (
    <div className={classN} key={Math.random(9999,1111)}>
        <div className="p-2 p-md-3 border border-1 border-light-subtle rounded-3 overflow-hidden">
            <div className="mb-4 position-relative">
            <Link to={`/product/${data.id}`}><img src={data.images[0]} alt="" className=" border-0 rounded-4 w-100 object-fit-contain"  style={{height: '200px'}} /></Link>
                <div className="position-absolute  bottom-0 start-0 w-100 pb-0 px-0">
                    <div className="d-flex justify-content-between align-items-center m-0">
                        <div className="d-flex p-2 align-items-center fs-8 bg-custom-light-t rounded-2 fw-medium fs-9">
                            {avg}&nbsp;
                            <span className="material-icons fs-7 text-custom">
                            star_rate
                            </span>
                            &nbsp;|&nbsp;{data.reviews.length}
                        </div>
                        <button className='btn btn-light p-2 rounded-2 bg-custom-light-t d-flex justify-content-center align-items-center'>
                           { icon === 'outline' ? <RiHeartLine className='fs-6' /> : <RiHeartFill className='fs-6 text-theme' />}
                        </button>
                        
                    </div>
                </div>
            </div>
            <p className="d-flex mb-0 align-items-center fw-bold fs-8">{data.brand ?? ''}</p>
            <Link to={`/product/${data.id}`} className="fs-6 text-truncate text-decoration-none d-block text-black fw-normal w-75">{data.title}</Link>
            <p className="fs-9 mb-0 text-secondary">{data.shippingInformation}</p>
            <div className="row">
                <div className="col">
                    <p className="fs-6 fw-bold mb-0">Rs. {data.price}</p>
                    <p className="fs-9 fw-normal text-secondary mb-0"><strike>Rs. {((data.price/100)*20 + data.price). toFixed(2)} </strike> &nbsp; <span className="text-danger">(20%)</span> </p>
                </div>
                <div className="col-auto row m-0 align-items-end">
                    <button className='btn btn-outline-warning p-0 d-flex justify-content-center align-items-center' style={{height: '40px', width: '40px'}} onClick={()=>addCart(data.id)}>
                    <PiShoppingCartSimpleFill className='fs-5' />
                    </button>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Card
